import { TableCard } from "@/components/table-card";
import { CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { apiClient, formatSearchQuery } from "@/lib/api";
import { components } from "@/lib/api.types";
import {
  keepPreviousData,
  queryOptions,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useState } from "react";

export const Route = createFileRoute("/_application/assets/locations/")({
  component: Locations,
  loader: ({ context, deps }) => {
    //eslint-disable-next-line @typescript-eslint/no-floating-promises
    context.queryClient.ensureQueryData(getOptions());
  },
});

function getOptions(settings: components["schemas"]["PaginationDto"] = {}) {
  return queryOptions({
    queryKey: [LOCATIONS_QUERY_KEY, settings],
    queryFn: () => getLocations(settings),
    placeholderData: keepPreviousData,
  });
}

async function getLocations(
  searchSettings: components["schemas"]["PaginationDto"] = {}
) {
  let search = formatSearchQuery(searchSettings.search);
  const response = await apiClient.POST("/location", {
    body: { ...searchSettings, search },
  });

  if (response.error != null) {
    throw new Error("Error getting locations");
  }

  return response.data;
}

const LOCATIONS_QUERY_KEY = "settings-locations";

export default function Locations() {
  const queryClient = useQueryClient();
  const [searchSettings, setSearchSettings] =
    useState<components["schemas"]["PaginationDto"]>();
  const navigate = useNavigate();

  function updateSearchSettings(
    settings: components["schemas"]["PaginationDto"]
  ) {
    setSearchSettings({
      ...searchSettings,
      ...settings,
    });
  }
  const query = useQuery(getOptions(searchSettings));

  return (
    <TableCard
      embedded
      onUpdate={updateSearchSettings}
      query={query}
      searchable
      onClickNavigate={(row) => ({
        to: "/assets/locations/$locationId",
        params: { locationId: row.id },
      })}
      headers={[
        { display: "City", key: "city", sortable: true },
        { display: "State", key: "state", sortable: true },
        { display: "Latitude", key: "lat", sortable: true },
        { display: "Longitude", key: "lon", sortable: true },
      ]}
    >
      <CardHeader>
        <div className="flex  gap-4 items-start lg:items-center flex-col lg:flex-row justify-between">
          <div className="flex flex-col gap-2">
            <CardTitle>Locations</CardTitle>
            <CardDescription>
              Locations associated with detections from your environment.
            </CardDescription>
          </div>
        </div>
      </CardHeader>
    </TableCard>
  );
}
