"use client";

import * as React from "react";

export const SERVICE_PROVIDER_PATH = "/sp";

import { NavCases } from "@/components/nav/nav-cases";
import { NavMainItems } from "@/components/nav/nav-main-items";
import { NavTeamSwitcher } from "@/components/nav/nav-team-switcher";
import { NavUser } from "@/components/nav/nav-user";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupAction,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarRail,
  useSidebar,
} from "@/components/ui/sidebar";
import { switchTeam } from "@/lib/api";
import { components } from "@/lib/api.types";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useQueryClient } from "@tanstack/react-query";
import {
  useNavigate,
  useRouteContext,
  useRouter,
} from "@tanstack/react-router";
import { ArrowLeft } from "lucide-react";

export function NavSidebar({
  assetCounts,
  ...props
}: React.ComponentProps<typeof Sidebar> & {
  assetCounts: components["schemas"]["AssetCounts"];
}) {
  const { isMobile, setOpenMobile } = useSidebar();
  const { user, team } = useRouteContext({ from: "/_application" });

  const navigate = useNavigate();
  const router = useRouter();
  const queryClient = useQueryClient();
  // If they navigate to a new page on mobile, close the sidebar
  React.useEffect(() => {
    if (isMobile) {
      setOpenMobile(false);
    }
  }, [location.pathname, isMobile, setOpenMobile]);

  const switchTeamCallback = React.useCallback(
    (teamId: string) =>
      switchTeam(teamId, navigate, router, queryClient, "/dashboard"),
    [navigate, router, queryClient, switchTeam]
  );

  return (
    <Sidebar collapsible="icon" {...props}>
      <SidebarHeader className="border-b h-16 group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12 transition-[width,height]">
        <NavTeamSwitcher />
      </SidebarHeader>
      {isMobile && (
        <SidebarGroup>
          <SidebarGroupLabel>Close Navigation</SidebarGroupLabel>
          <SidebarGroupAction onClick={() => setOpenMobile(false)}>
            <XMarkIcon />
          </SidebarGroupAction>
        </SidebarGroup>
      )}
      {user.serviceProvider && !team.serviceProvider && (
        <SidebarGroup
          onClick={() => switchTeamCallback(user.parentTeamId)}
          className="hover:bg-muted cursor-pointer bg-primary/20"
        >
          <SidebarGroupLabel>
            <ArrowLeft className="size-4 mr-1" /> Back To Service Provider
          </SidebarGroupLabel>
        </SidebarGroup>
      )}
      <SidebarContent>
        <NavCases />
        <NavMainItems assetCounts={assetCounts} />
      </SidebarContent>
      <SidebarFooter className="flex flex-col p-0 gap-2">
        <NavUser />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
}
