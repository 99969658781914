import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { apiClient } from "@/lib/api";
import { showArticle } from "@intercom/messenger-js-sdk";
import { DOCS } from "@wire/shared";
import copy from "copy-to-clipboard";
import { useEffect, useRef, useState } from "react";
import { toast } from "sonner";

export default function AWS(props: { onComplete: () => void }) {
  const [roleArn, setRoleArn] = useState<string>();
  const [externalId, setExternalId] = useState<string>();
  const [error, setError] = useState<string>();
  const [accountIds, setAccountIds] = useState<string[]>([]);
  const [apiKey, setApiKey] = useState<string>();
  const [tab, setTab] = useState<"single" | "all">("single");
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    async function fetchApiKey() {
      const response = await apiClient.GET("/integration/aws/remote");
      if (response.error != null) {
        setError(response.error.message ?? "Error fetching API key");
        return;
      }
      setApiKey(response.data.apiKey);
    }
    if (tab == "all") {
      void fetchApiKey();
    }
  }, [tab]);

  async function submitSingle() {
    if (roleArn == null) {
      setError("Role ARN is required");
      return;
    }
    if (externalId == null) {
      setError("External ID is required");
      return;
    }

    const response = await apiClient.PUT("/integration/aws", {
      body: {
        roleArn,
        externalId,
      },
    });

    if (response.error != null) {
      setError(
        response.error.message ??
          "Error adding integration, please check the instructions"
      );
      return;
    }

    props.onComplete();
  }

  return (
    <>
      <div className="space-y-4">
        <Tabs onValueChange={(value) => setTab(value as any)} value={tab}>
          <TabsList>
            <TabsTrigger value="single">Single Account</TabsTrigger>
            <TabsTrigger value="all">Organization-wide</TabsTrigger>
          </TabsList>
          <TabsContent value="single">
            <div>
              <Label>Role ARN</Label>
              <Input
                value={roleArn}
                onChange={(e) => setRoleArn(e.target.value)}
                name="roleArn"
                type="text"
                placeholder="arn:aws:iam::123456789012:role/ExampleRole"
              />
            </div>
            <div>
              <Label>External ID</Label>
              <Input
                type="text"
                value={externalId}
                onChange={(e) => setExternalId(e.target.value)}
                name="externalId"
                placeholder="1234567890"
              />
            </div>
            {error && <p className="text-sm text-red-500">{error}</p>}
            <div className="flex flex-col lg:flex-row gap-4 mt-4">
              <Button onClick={submitSingle}>Integrate</Button>
              <Button
                onClick={() => showArticle(DOCS.INTEGRATIONS.AWS)}
                variant="outline"
              >
                Integration Instructions
              </Button>
            </div>
          </TabsContent>
          <TabsContent value="all">
            <div className="flex flex-col gap-4">
              <div className="border rounded-lg p-4 space-y-4">
                <h3 className="font-medium">Deploy StackSet</h3>
                <p className="text-sm text-muted-foreground">
                  Deploy the AWS CloudFormation StackSet to create the required
                  IAM roles across your organization.
                </p>
                <div>
                  <Label>API Key (click to copy)</Label>
                  <Input
                    value={apiKey}
                    readOnly
                    className="cursor-pointer"
                    name="apiKey"
                    onClick={(e) => {
                      if (apiKey == null) {
                        toast.error("No API key available");
                        return;
                      }
                      copy(apiKey);
                      toast.success("API key copied to clipboard");
                    }}
                    type="password"
                    placeholder="················"
                  />
                </div>
                <Button
                  onClick={() => showArticle(DOCS.INTEGRATIONS.AWS)}
                  variant="outline"
                  className="self-start"
                >
                  View Deployment Instructions
                </Button>
              </div>
            </div>
          </TabsContent>
        </Tabs>
      </div>
    </>
  );
}
