import { AppLayout } from "@/components/app-layout";
import { TableCard } from "@/components/table-card";
import { Button } from "@/components/ui/button";
import { CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { useEnforceServiceProvider } from "@/hooks/use-enforce-service-provider";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { createFileRoute, Link } from "@tanstack/react-router";
import { useState } from "react";

export const Route = createFileRoute("/_application/clients")({
  component: Clients,
  loader: ({ context }) => {
    void context.queryClient.ensureQueryData(getClientOptions());
  },
});

async function getServiceProviderClients(
  dto: components["schemas"]["PaginationDto"] = {}
) {
  const response = await apiClient.POST("/team", { body: dto });
  if (response.error != null) {
    throw new Error(response.error.message);
  }
  return response.data;
}

const SERVICE_PROVIDER_CLIENTS_QUERY = "service-provider-clients";
function getClientOptions(search: components["schemas"]["PaginationDto"] = {}) {
  return queryOptions({
    queryKey: [SERVICE_PROVIDER_CLIENTS_QUERY, search],
    queryFn: () => getServiceProviderClients(search),
  });
}

function Clients() {
  useEnforceServiceProvider();

  const [search, setSearch] = useState<components["schemas"]["PaginationDto"]>(
    {}
  );
  const teamQuery = useQuery(getClientOptions(search));
  return (
    <AppLayout>
      <TableCard
        query={teamQuery}
        onClick={(row) => {
          window.open(`/dashboard?switchTeamId=${row.id}`, "_blank");
        }}
        headers={[
          {
            display: "Name",
            key: "name",
            format: (_, row) => (
              <div className="overflow-hidden w-full">
                <img
                  src={row.logoUrl}
                  className="w-4 inline-block h-4 rounded-sm mr-1"
                />
                {/* For some reason we need mr-5 because the table won't take the image into the width accounting */}
                <div className="inline-block mr-5">{row.name}</div>
              </div>
            ),
          },
          {
            display: "Demo Team",
            key: "demo",
          },
          {
            display: "Test Mode",
            key: "testMode",
          },
          {
            display: "Created At",
            key: "createdAt",
            dateTime: true,
          },
          {
            display: "Escalation Email",
            key: "escalationEmail",
            format: (_, row) => (
              <div className="overflow-hidden w-full">
                {row.escalationEmail ?? (
                  <span className="text-xs text-muted-foreground">
                    Inherited from parent
                  </span>
                )}
              </div>
            ),
          },
          {
            display: "Endpoint Auto Containment",
            key: "endpointAutoContainment",
          },
          {
            display: "Identity Auto Containment",
            key: "identityAutoContainment",
          },
          {
            display: "Endpoint HVA Auto Containment",
            key: "autoContainEndpointHVA",
          },
          {
            display: "Endpoint Servers Auto Containment",
            key: "autoContainEndpointServers",
          },
          {
            display: "Endpoint LST Auto Containment",
            key: "autoContainLSTEndpoint",
          },
          {
            display: "Endpoint Unmitigated Malware Auto Containment",
            key: "autoContainUnmitigatedMalware",
          },
          {
            display: "Identity VIP Auto Containment",
            key: "autoContainIdentityVIP",
          },
          {
            display: "Max Auto Containments Per Day",
            key: "maxAutoContainmentsPerDay",
          },
          {
            display: "Max Chat Ops Per Day",
            key: "maxChatOpsPerDay",
          },
        ]}
        onUpdate={setSearch}
      >
        <CardHeader className="flex justify-between flex-row items-center">
          <div>
            <CardTitle>Clients</CardTitle>
            <CardDescription>
              High-level summaries for clients that you provide services for.
            </CardDescription>
          </div>
          <div>
            <Link to="/settings/add-new-client">
              <Button>Add New Client</Button>
            </Link>
          </div>
        </CardHeader>
      </TableCard>
    </AppLayout>
  );
}
