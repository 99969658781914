"use client";

import { ChevronsUpDown } from "lucide-react";
import * as React from "react";

import { Combobox } from "@/components/ui/combo-box";
import { Label } from "@/components/ui/label";
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "@/components/ui/sidebar";
import { Switch } from "@/components/ui/switch";
import { switchTeam } from "@/lib/api";
import { useQueryClient } from "@tanstack/react-query";
import {
  useNavigate,
  useRouteContext,
  useRouter,
} from "@tanstack/react-router";

export function NavTeamSwitcher() {
  const { isMobile } = useSidebar();
  const { team, teams, user } = useRouteContext({ from: "/_application" });
  const [hideDemo, setHideDemo] = React.useState(
    localStorage.getItem("hideDemo") === "true"
  );
  const navigate = useNavigate();
  const router = useRouter();
  const queryClient = useQueryClient();
  const [search, setSearch] = React.useState("");

  React.useEffect(() => {
    localStorage.setItem("hideDemo", hideDemo.toString());
  }, [hideDemo]);

  const selectableTeams = React.useMemo(() => {
    return (
      teams
        .filter((v) => v.id == team.id || (hideDemo ? !v.demo : true))
        // Sort priority: name match, parent team name match
        .sort((a, b) => {
          const aNameMatch = a.name
            .toLowerCase()
            .includes(search.toLowerCase());
          const bNameMatch = b.name
            .toLowerCase()
            .includes(search.toLowerCase());
          const aParentMatch = (a.parentTeamName?.toLowerCase() || "").includes(
            search.toLowerCase()
          );
          const bParentMatch = (b.parentTeamName?.toLowerCase() || "").includes(
            search.toLowerCase()
          );

          if (aNameMatch && !bNameMatch) return -1;
          if (!aNameMatch && bNameMatch) return 1;
          if (aParentMatch && !bParentMatch) return -1;
          if (!aParentMatch && bParentMatch) return 1;
          return 0;
        })
    );
  }, [teams, hideDemo, team.id, search]);

  const shouldShowDemoToggle = React.useMemo(() => {
    // Make sure there is at least one demo team available, and at least one non-demo team available
    return (
      teams.filter((v) => v.demo).length > 0 &&
      teams.filter((v) => !v.demo).length > 0
    );
  }, [teams]);

  const sidebarTeamLabel = React.useMemo(() => {
    return (
      <>
        <div className="flex aspect-square border shadow-sm p-1 size-8 items-center justify-center rounded-lg">
          <img
            src={team.logoUrl}
            className="h-full w-full min-h-4 min-w-4"
            alt="Team Logo"
          />
        </div>
        <div className="grid flex-1 text-left text-sm leading-tight">
          <span className="truncate font-semibold">{team.name}</span>
          {teams.length > 1 && (
            <span className="truncate text-xs">
              {team.id === user.parentTeamId
                ? "Parent Tenant"
                : team.serviceProvider
                  ? "Service Provider"
                  : team.parentTeamName
                    ? `Managed by ${team.parentTeamName}`
                    : ""}
            </span>
          )}
        </div>
      </>
    );
  }, [team, teams, user]);

  const comboboxValues = React.useMemo(() => {
    return selectableTeams.map((t) => ({
      value: t.id,
      search: `${t.name} ${t.parentTeamName ?? ""}`,
      label: (
        <div className="flex items-center gap-2">
          <div className="flex size-6 items-center justify-center rounded-sm border">
            <img
              src={t.logoUrl}
              className="size-4 shrink-0"
              alt={`${t.name} Logo`}
            />
          </div>
          <div className="flex flex-col gap-1">
            <div>{t.name}</div>
            <div className="text-muted-foreground text-xs">
              {t.id === user.parentTeamId
                ? "Parent Tenant"
                : t.serviceProvider
                  ? "Service Provider"
                  : t.parentTeamName
                    ? `Managed by ${t.parentTeamName}`
                    : ""}
            </div>
          </div>
        </div>
      ),
    }));
  }, [selectableTeams, teams.length, user.parentTeamId]);

  const switchTeamCallback = React.useCallback(
    (teamId: string) => switchTeam(teamId, navigate, router, queryClient),
    [navigate, router, queryClient, switchTeam]
  );

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <Combobox
          modal={isMobile}
          values={comboboxValues}
          value={team.id}
          placeholder="Select a client..."
          emptyMessage="No teams found"
          onSearch={setSearch}
          onSelect={switchTeamCallback}
          popoverSide={isMobile ? "bottom" : "right"}
          popoverClassName="min-w-[300px]"
          footer={
            shouldShowDemoToggle && (
              <div className="flex items-center justify-between p-2 border-t">
                <Label className="text-xs text-muted-foreground">
                  Hide Demo
                </Label>
                <Switch checked={hideDemo} onCheckedChange={setHideDemo} />
              </div>
            )
          }
        >
          <SidebarMenuButton
            isActive={false}
            size="lg"
            className="data-[state=open]:bg-sidebar-accent min-h-4 min-w-4 data-[state=open]:text-sidebar-accent-foreground"
          >
            {sidebarTeamLabel}
            {teams.length > 1 && <ChevronsUpDown className="ml-auto" />}
          </SidebarMenuButton>
        </Combobox>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
