import { AppLayout } from "@/components/app-layout";
import ManageExclusion from "@/components/manage-exclusion";
import { TableCard } from "@/components/table-card";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { dateTime } from "@/lib/time";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@radix-ui/react-icons";
import {
  keepPreviousData,
  queryOptions,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import {
  createDetectionQuery,
  DETECTION_QUERY_FIELDS,
  getStatusConfigByStatus,
  QUERY_OPERATOR,
  ROLE,
} from "@wire/shared";
import { useMemo, useState } from "react";
import { toast } from "sonner";

export const Route = createFileRoute(
  "/_application/assets/endpoints/$endpointId"
)({
  component: Location,
  loader: async ({ params, context }) => {
    const [endpoint] = await Promise.all([
      context.queryClient.ensureQueryData(
        getEndpointOptions(params.endpointId)
      ),
      context.queryClient.ensureQueryData(
        getEndpointUserOptions({ endpointId: params.endpointId })
      ),
    ]);
    context.title = endpoint.displayName;
  },
});

async function getEndpoint(endpointId: string) {
  const endpoint = await apiClient.GET("/endpoint/{id}", {
    params: { path: { id: endpointId } },
  });
  if (endpoint.error != null) {
    throw new Error("Error getting endpoint information");
  }
  return endpoint.data;
}

export const ENDPOINT_USERS_QUERY_KEY = "endpoint-users";
const getEndpointUserOptions = (
  dto: components["schemas"]["DirectoryUserSearchDto"]
) =>
  queryOptions({
    queryKey: [ENDPOINT_USERS_QUERY_KEY, dto.endpointId],
    queryFn: () => getEndpointUsers(dto),
  });

async function getEndpointUsers(
  dto: components["schemas"]["DirectoryUserSearchDto"]
) {
  const endpoint = await apiClient.POST("/directory", {
    body: {
      ...dto,
    },
  });
  if (endpoint.error != null) {
    throw new Error("Error getting endpoint information");
  }
  return endpoint.data;
}

export const ENDPOINT_QUERY_KEY = "endpoint";
const getEndpointOptions = (endpointId: string) =>
  queryOptions({
    queryKey: [ENDPOINT_QUERY_KEY, endpointId],
    queryFn: () => getEndpoint(endpointId),
  });

async function searchCases(
  searchSettings: components["schemas"]["SearchCasesDto"],
  endpointId: string
) {
  const [cases] = await Promise.all([
    apiClient.POST("/cases", {
      body: { ...searchSettings, assetType: "ENDPOINT", assetId: endpointId },
    }),
  ]);
  if (cases.error != null) {
    throw new Error("Error getting endpoint information");
  }
  return cases.data;
}
export const CASES_QUERY_KEY = "case-settings";
const getSearchCaseOptions = (
  searchSettings: components["schemas"]["SearchCasesDto"],
  endpointId: string
) =>
  queryOptions({
    queryKey: [CASES_QUERY_KEY, searchSettings, endpointId],
    queryFn: () => searchCases(searchSettings, endpointId),
    placeholderData: keepPreviousData,
  });

function Location() {
  const { endpointId } = Route.useParams();
  const [createExclusionDialogOpen, setCreateExclusionDialogOpen] =
    useState(false);
  const [searchSettings, setSearchSettings] = useState<
    components["schemas"]["SearchCasesDto"]
  >({});
  const [userSearchSettings, setUserSearchSettings] = useState<
    components["schemas"]["DirectoryUserSearchDto"]
  >({ endpointId });
  const casesQuery = useQuery(getSearchCaseOptions(searchSettings, endpointId));
  const { data: endpoint } = useSuspenseQuery(getEndpointOptions(endpointId));
  const users = useQuery(getEndpointUserOptions(userSearchSettings));
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  async function updateEndpoint(
    dto: components["schemas"]["UpdateEndpointDto"]
  ) {
    const response = await apiClient.PATCH("/endpoint/{id}", {
      params: { path: { id: endpointId } },
      body: dto,
    });
    if (response.error != null) {
      toast.error(response.error.message);
    }
    await queryClient.invalidateQueries({
      queryKey: [ENDPOINT_QUERY_KEY, endpointId],
    });
  }

  const defaultExclusionQuery = useMemo(() => {
    let values: [string, string] = ["", ""];
    if (endpoint.id != null) {
      values = [DETECTION_QUERY_FIELDS.ENDPOINT_ID, `"${endpoint.id}"`];
    } else if (endpoint.name != null) {
      values = [DETECTION_QUERY_FIELDS.ENDPOINT_HOSTNAME, `"${endpoint.name}"`];
    } else {
      return "";
    }
    return createDetectionQuery(
      values[0],
      QUERY_OPERATOR.ALL_ARRAY_VALUES_EQUAL,
      values[1]
    );
  }, []);

  return (
    <AppLayout>
      {" "}
      <ManageExclusion
        title="Create Exclusion"
        query={defaultExclusionQuery}
        name={endpoint.displayName}
        providedDetectionSid
        detectionSid={casesQuery.data?.data[0]?.detectionSids[0]}
        description="Automatically close future detections that match this query"
        open={createExclusionDialogOpen}
        onClose={() => setCreateExclusionDialogOpen(false)}
      />
      <div className="flex flex-col gap-4">
        <Card>
          <CardHeader className="bg-muted/50 mb-4 flex space-y-0 items-center flex-col gap-4 lg:flex-row lg:justify-between">
            <div>
              <CardTitle>
                {endpoint.name ??
                  endpoint.privateIpAddress ??
                  endpoint.edrSourceId ??
                  endpoint.mdmSourceId}
              </CardTitle>
              <CardDescription>Endpoint</CardDescription>
            </div>
            <div className="flex flex-col gap-4 lg:flex-row">
              <DropdownMenu requiredRole={ROLE.ANALYST}>
                <DropdownMenuTrigger asChild>
                  <Button>Actions</Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem
                    onClick={() => setCreateExclusionDialogOpen(true)}
                  >
                    <div>
                      <h4 className="font-semibold">Create Exclusion</h4>
                      <p className="text-muted-foreground">
                        Automatically ignore detections that are associated with
                        this user
                      </p>
                    </div>
                  </DropdownMenuItem>
                  {endpoint.hva && (
                    <DropdownMenuItem
                      requiredRole={ROLE.ANALYST}
                      onClick={() => updateEndpoint({ hva: false })}
                    >
                      Remove HVA Status
                    </DropdownMenuItem>
                  )}
                  {!endpoint.hva && (
                    <DropdownMenuItem
                      requiredRole={ROLE.ANALYST}
                      onClick={() => updateEndpoint({ hva: true })}
                    >
                      Set as HVA
                    </DropdownMenuItem>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </CardHeader>
          <CardContent className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
            <div>
              <h2 className="font-semibold">Created At</h2>
              <p className="text-sm">{dateTime(endpoint.createdAt)}</p>
            </div>
            <div>
              <h2 className="font-semibold">Hostname</h2>
              <p className="text-sm">{endpoint.name}</p>
            </div>
            <div>
              <h2 className="font-semibold">Operating System</h2>
              <p className="text-sm">{endpoint.operatingSystem}</p>
            </div>

            <div>
              <h2 className="font-semibold">High Value Target</h2>
              <p className="text-sm">
                {endpoint.hva ? (
                  <CheckIcon className="text-green-500 h-6 w-6" />
                ) : (
                  <XMarkIcon className="text-red-500 h-6 w-6" />
                )}
              </p>
            </div>
            <div>
              <h2 className="font-semibold">Workstation</h2>
              <p className="text-sm">
                {endpoint.workstation ? (
                  <CheckIcon className="text-green-500 h-6 w-6" />
                ) : (
                  <XMarkIcon className="text-red-500 h-6 w-6" />
                )}
              </p>
            </div>
            <div>
              <h2 className="font-semibold">Server</h2>
              <p className="text-sm">
                {endpoint.server ? (
                  <CheckIcon className="text-green-500 h-6 w-6" />
                ) : (
                  <XMarkIcon className="text-red-500 h-6 w-6" />
                )}
              </p>
            </div>
            <div>
              <h2 className="font-semibold">Public IP</h2>
              <p className="text-sm">
                {endpoint.publicIPs?.map((v, idx) => (
                  <Link
                    key={v.id}
                    className="text-blue-500"
                    to="/assets/ips/$ipId"
                    params={{ ipId: v.id }}
                  >
                    {v.ipv4 ?? v.ipv6}
                    {idx < endpoint.publicIPs!.length - 1 && ", "}
                  </Link>
                )) ?? "-"}
              </p>
            </div>
            <div>
              <h2 className="font-semibold">Private IP Address</h2>
              <p className="text-sm">{endpoint.privateIpAddress ?? "-"}</p>
            </div>
            <div>
              <h2 className="font-semibold">EDR Source ID</h2>
              <p className="text-sm overflow-ellipsis overflow-hidden">
                {endpoint.edrSourceId ?? "-"}
              </p>
            </div>
            <div>
              <h2 className="font-semibold">MDM Source ID</h2>
              <p className="text-sm overflow-ellipsis overflow-hidden">
                {endpoint.mdmSourceId ?? "-"}
              </p>
            </div>
            <div>
              <h2 className="font-semibold">First Seen At</h2>
              <p className="text-sm">{dateTime(endpoint.createdAt)}</p>
            </div>
            {endpoint.updatedAt != null && (
              <div>
                <h2 className="font-semibold">Updated At</h2>
                <p className="text-sm">{dateTime(endpoint.updatedAt)}</p>
              </div>
            )}
          </CardContent>
        </Card>
        <TableCard
          onClick={(row) =>
            navigate({
              to: "/cases/$caseId",
              params: { caseId: row.id },
            })
          }
          query={casesQuery}
          onUpdate={(settings) =>
            setSearchSettings({ ...searchSettings, ...settings })
          }
          headers={[
            {
              display: "ID",
              key: "sid",
              sortable: true,
            },
            {
              display: "Name",
              key: "name",
              sortable: true,
            },
            {
              display: "Status",
              key: "status",
              format: (value) => getStatusConfigByStatus(value)?.display,
            },
            {
              display: "Created At",
              key: "firstDetectionSourceDetectedAt",
              sortable: true,
              format(value) {
                return dateTime(value);
              },
            },
          ]}
        >
          <CardHeader className="bg-muted/50 mb-4">
            <CardTitle>Related Cases</CardTitle>
            <CardDescription>
              Cases that this endpoint has been associated with
            </CardDescription>
          </CardHeader>
        </TableCard>
        <TableCard
          onClick={(row) =>
            navigate({
              to: "/assets/users/$userId",
              params: { userId: row.id },
            })
          }
          query={users}
          onUpdate={(settings) =>
            setUserSearchSettings({ ...userSearchSettings, ...settings })
          }
          headers={[
            {
              display: "Name",
              key: "name",
              sortable: true,
            },
            {
              display: "Email",
              key: "email",
              sortable: true,
            },
            {
              display: "Title",
              key: "title",
              sortable: true,
            },
          ]}
        >
          <CardHeader className="bg-muted/50 mb-4">
            <CardTitle>Related Users</CardTitle>
            <CardDescription>
              Users that this endpoint has been associated with
            </CardDescription>
          </CardHeader>
        </TableCard>
      </div>
    </AppLayout>
  );
}
