import { IntegrationLogo } from "@/components/settings/add-integration";
import DeleteIntegration from "@/components/settings/integrations/delete-integration";
import { UpdateGooglePermissions } from "@/components/settings/integrations/google";
import HealthCheckIntegration from "@/components/settings/integrations/health-check-integration";
import { UpdateMicrosoftPermissions } from "@/components/settings/integrations/microsoft";
import ToggleIntegration from "@/components/settings/integrations/trigger-integration";
import { TableCard } from "@/components/table-card";
import { Badge, BadgeProps } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";

import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { queryClient } from "@/lib/tanstack";
import { dateTime, dateTimeBuilder } from "@/lib/time";
import { camelCaseToTitleCase, cn } from "@/lib/utils";
import {
  getIntegrationLogDailyOptions,
  getIntegrationLogOptions,
  getIntegrationOptions,
  INTEGRATION_QUERY_KEY,
} from "@/routes/_application/settings/integrations/$integrationId";
import {
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { createFileRoute, SearchSchemaInput } from "@tanstack/react-router";
import {
  getIntegrationConfigByPlatform,
  getIntegrationTypeConfigByType,
  IntegrationLogType,
  IntegrationLogTypeConfig,
  IntegrationPlatform,
  IntegrationType,
  ROLE,
} from "@wire/shared";
import { ChevronDown, ChevronRight } from "lucide-react";
import moment from "moment";
import numeral from "numeral";
import { useMemo, useState } from "react";
import { toast } from "sonner";

export const Route = createFileRoute(
  "/_application/settings/integrations/$integrationId/"
)({
  validateSearch: (
    search: {
      metadataId?: string;
    } & SearchSchemaInput
  ) => {
    return {
      metadataId: search.metadataId,
    };
  },
  component: Integration,
});

function getLogTypeBadgeVariant(
  type: IntegrationLogType
): BadgeProps["variant"] {
  switch (type) {
    case IntegrationLogType.ERROR:
      return "destructive";
    case IntegrationLogType.WARNING:
      return "warning";
    default:
      return "success";
  }
}

function Integration() {
  const { integrationId } = Route.useParams();
  const { metadataId } = Route.useSearch();
  const queryClient = useQueryClient();
  const [isLogsOpen, setIsLogsOpen] = useState(false);
  const [logSearchSettings, setLogSearchSettings] = useState<
    components["schemas"]["IntegrationLogSearchDto"]
  >({
    integrationId,
  });

  const {
    data: { integration },
  } = useSuspenseQuery(getIntegrationOptions(integrationId));

  const dailyLogSearchSettings = useMemo(
    () => ({
      integrationMetadataId: logSearchSettings.integrationMetadataId,
      integrationId: logSearchSettings.integrationId,
    }),
    [logSearchSettings]
  );

  const dailyLogQuery = useQuery(
    getIntegrationLogDailyOptions(dailyLogSearchSettings)
  );
  const logQuery = useQuery(getIntegrationLogOptions(logSearchSettings));
  const formattedDailyLogs = useMemo(() => {
    if (dailyLogQuery.isLoading) return [];
    const dayGroups: {
      [day: string]: {
        [type: string]: {
          count: number;
          datetime: string;
        };
      };
    } = dailyLogQuery.data?.reduce((acc: any, v) => {
      const day = moment(v.day).format("MMM DD");
      if (acc[day] == null) {
        acc[day] = {};
      }
      Object.values(IntegrationLogType).forEach((type) => {
        if (v.group == type.toString()) {
          acc[day][type] = {
            count: v.count,
            datetime: v.day,
          };
        }
      });
      acc[day].day = { datetime: day };
      return acc;
    }, {});

    // Convert to array and find first non-zero day
    const dayArray = Object.entries(dayGroups);
    const firstNonZeroIndex = dayArray.findIndex(([_, values]) => {
      const total = Object.entries(values).reduce((sum, [key, value]) => {
        if (key === "day") return sum;
        return sum + value.count;
      }, 0);
      return total >= 0;
    });

    // Return array starting from first non-zero day
    return dayArray.slice(firstNonZeroIndex).map(([_, value]) => value);
  }, [dailyLogQuery.data]);

  const reversedDailyLogs = useMemo(() => {
    return [...formattedDailyLogs].reverse();
  }, [formattedDailyLogs]);

  const chartConfig = useMemo(
    () => ({
      [IntegrationLogType.ERROR]: {
        label: "Error",
        color: "hsl(var(--chart-destructive))",
      },
      [IntegrationLogType.WARNING]: {
        label: "Warning",
        color: "hsl(var(--chart-warning))",
      },
      [IntegrationLogType.INFO]: {
        label: "Info",
        color: "hsl(var(--chart-success))",
      },
    }),
    []
  );

  const integrationConfig = useMemo(() => {
    return getIntegrationConfigByPlatform(integration.platform);
  }, [integration]);

  const permissionsUpdateAvailable = useMemo(() => {
    return Object.values(integration.metadata).some(
      (metadata) => metadata.permissionUpdateAvailable
    );
  }, [integration]);

  async function testIntegration() {
    const response = await apiClient.POST("/integration/{id}/test", {
      params: { path: { id: integrationId } },
    });
    if (response.error != null) {
      toast.error(response.error.message);
    } else {
      toast.success(
        `Integration tested, please check ${integrationConfig.display}`
      );
    }
  }

  const metadata = useMemo(() => {
    return Object.values(integration.metadata).filter((v) => v.type != null);
  }, [integration]);

  return (
    <div className="h-full flex flex-col divide-y">
      <CardHeader className="bg-background ">
        <div className="flex flex-col gap-4 lg:flex-row lg:items-center items-start lg:justify-between">
          <div>
            <CardTitle className="flex gap-1 text-xl items-center">
              <IntegrationLogo
                platform={integration.platform}
                className="h-6 w-6"
              />
              {integrationConfig.display}
              {integrationConfig.beta && <Badge className="ml-1">Beta</Badge>}

              {permissionsUpdateAvailable && (
                <div className="ml-2">
                  <UpdatePermissionsButton integration={integration} />
                </div>
              )}
            </CardTitle>
            {integration.identifier != null && (
              <CardDescription>{integration.identifier}</CardDescription>
            )}
          </div>
          <div className="flex flex-wrap gap-2">
            {integrationConfig.types.includes(IntegrationType.CHAT) && (
              <Button
                requiredRole={ROLE.ADMIN}
                onClick={testIntegration}
                variant="outline"
              >
                Test
              </Button>
            )}
            <DeleteIntegration integration={integration} />
            <ToggleIntegration
              onComplete={() =>
                queryClient.invalidateQueries({
                  queryKey: [INTEGRATION_QUERY_KEY, integration.id],
                })
              }
              integration={integration}
            />
            <HealthCheckIntegration
              onComplete={() =>
                queryClient.invalidateQueries({
                  queryKey: [INTEGRATION_QUERY_KEY, integration.id],
                })
              }
              integration={integration}
            />
          </div>
        </div>
      </CardHeader>
      <Card className="rounded-none border-0 shadow-none">
        <CardHeader>
          <CardTitle>Integration Events</CardTitle>
          <div>
            <div className="flex justify-between self-start flex-row-reverse overflow-y-auto overflow-x-auto grid-flow-col gap-1 h-auto rounded-md bg-muted/50 p-2">
              {reversedDailyLogs.map((dayData) => (
                <div key={dayData.day.datetime} className="flex flex-col">
                  <div className="text-[.6rem] text-center w-6 text-muted-foreground mb-1">
                    {dayData.day.datetime}
                  </div>
                  <div className="flex-1 flex flex-col w-7 gap-1">
                    {Object.entries(chartConfig).map(([type, config]) => (
                      <div
                        onClick={() => {
                          if (
                            logSearchSettings.day == dayData[type]?.datetime &&
                            logSearchSettings.type == type
                          ) {
                            setLogSearchSettings({
                              ...logSearchSettings,
                              day: undefined,
                              type: undefined,
                            });
                          } else {
                            setLogSearchSettings({
                              ...logSearchSettings,
                              day: dayData[type].datetime,
                              type: type as IntegrationLogType,
                            });
                            setIsLogsOpen(true);
                          }
                        }}
                        key={type}
                        className={cn(
                          "flex-1 rounded cursor-pointer overflow-hidden flex items-center justify-center text-xs",
                          {
                            "ring-1 ring-primary":
                              logSearchSettings.day ==
                                dayData[type]?.datetime &&
                              logSearchSettings.type == type,
                          }
                        )}
                        style={{
                          backgroundColor: dayData[type]?.count
                            ? config.color
                            : "transparent",
                          color: dayData[type]?.count ? "white" : "inherit",
                        }}
                      >
                        {dayData[type] != null && dayData[type].count > 0
                          ? numeral(dayData[type]?.count).format("0,0a")
                          : "-"}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </CardHeader>
        <CardContent className="space-y-4">
          {/* Collapsible Logs Section */}
          <Collapsible open={isLogsOpen} onOpenChange={setIsLogsOpen}>
            <CollapsibleTrigger asChild>
              <div className="flex cursor-pointer items-center gap-1 text-xs">
                <p>View Logs</p>
                {isLogsOpen ? (
                  <ChevronDown className="h-3 w-3" />
                ) : (
                  <ChevronRight className="h-3 w-3" />
                )}
              </div>
            </CollapsibleTrigger>
            <CollapsibleContent>
              <div className="pt-4">
                <TableCard
                  embedded
                  query={logQuery}
                  className="min-h-0"
                  contentClassName="px-0"
                  footerClassName="px-0"
                  onUpdate={(e) => {
                    setLogSearchSettings({
                      ...logSearchSettings,
                      ...e,
                    });
                  }}
                  searchable
                  searchFilters={[
                    {
                      placeholder: "Feature",
                      defaultValue: null,
                      values: [
                        { key: "all", display: "All" },
                        ...metadata.map((v) => ({
                          key: v.id,
                          display: getIntegrationTypeConfigByType(v.type as any)
                            ?.display,
                        })),
                      ],
                      onSelect: (e: string | undefined) => {
                        if (e === "all") {
                          e = undefined;
                        }
                        setLogSearchSettings({
                          ...logSearchSettings,
                          integrationMetadataId: e,
                        });
                      },
                    },
                  ]}
                  headers={[
                    {
                      key: "integrationMetadataId",
                      display: "Feature",
                      format: (v) =>
                        getIntegrationTypeConfigByType(
                          Object.values(integration.metadata).find(
                            (m) => m.id == v
                          )?.type as any
                        )?.display ?? "-",
                    },
                    {
                      key: "context",
                      display: "Context",
                      format: (v) => camelCaseToTitleCase(v),
                    },
                    {
                      key: "message",
                      display: "Log Message",
                      format: (v) => v,
                    },
                    {
                      key: "createdAt",
                      display: "Event Time",
                      format: (v) => dateTime(v),
                    },
                    {
                      key: "type",
                      display: "Log Level",
                      format: (v) => (
                        <Badge
                          variant={getLogTypeBadgeVariant(
                            v as IntegrationLogType
                          )}
                        >
                          {
                            IntegrationLogTypeConfig[v as IntegrationLogType]
                              ?.display
                          }
                        </Badge>
                      ),
                    },
                  ]}
                />
              </div>
            </CollapsibleContent>
          </Collapsible>
        </CardContent>
      </Card>
      <Card className="rounded-none border-0 shadow-none">
        <CardHeader>
          <CardTitle>Integration Features</CardTitle>
          <CardDescription>
            Features that are enabled for this integration
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="flex flex-row flex-wrap gap-2">
            {metadata.map((metadata) => (
              <div
                className={cn("flex flex-col border rounded-md p-4 gap-2", {
                  "bg-green-100 dark:bg-green-700": metadata?.healthy,
                  "bg-yellow-100 dark:bg-yellow-700":
                    (metadata?.consecutiveFailures ?? 0) > 0,
                  "bg-red-100 dark:bg-red-700": !metadata?.healthy,
                  "bg-muted dark:bg-muted":
                    metadata?.ignored ||
                    !integration.enabled ||
                    !metadata?.activated,
                })}
                key={metadata.id}
              >
                <div>
                  <div className="font-medium text-sm">
                    {
                      getIntegrationTypeConfigByType(metadata.type as any)
                        ?.display
                    }
                  </div>
                  <div className="text-xs text-muted-foreground">
                    {metadata?.lastUsedAt ? (
                      <>
                        Last used{" "}
                        {dateTimeBuilder(metadata?.lastUsedAt).fromNow()}
                      </>
                    ) : (
                      <>Not used yet</>
                    )}
                  </div>
                </div>
                <div className="text-xs text-muted-foreground flex items-center gap-1">
                  {getMetadataHealthDescription(metadata, integration)}
                  {!metadata?.healthy &&
                    integration.enabled &&
                    metadata?.activated && (
                      <div
                        className="cursor-pointer"
                        onClick={() =>
                          updateIgnore(
                            integration.id,
                            metadata.id,
                            !metadata.ignored
                          )
                        }
                      >
                        &middot; Click to{" "}
                        {metadata.ignored ? "unignore" : "ignore"}
                      </div>
                    )}
                </div>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
async function updateIgnore(
  integrationId: string,
  metadataId: string,
  ignored: boolean
) {
  const response = await apiClient.PATCH(
    "/integration/{integrationId}/metadata/{metadataId}",
    {
      params: {
        path: {
          integrationId,
          metadataId,
        },
      },
      body: { ignored },
    }
  );
  if (response.error != null) {
    toast.error((response as any)?.error?.message);
  }
  await queryClient.invalidateQueries({
    queryKey: [INTEGRATION_QUERY_KEY, integrationId],
  });
}

function getMetadataHealthDescription(
  metadata: components["schemas"]["MetadataItem"],
  integration: components["schemas"]["Integration"]
) {
  if (!integration.enabled) return "Disabled";
  if (metadata.ignored) return "Ignored";
  if (!metadata.activated) return "No license found";
  if (metadata.consecutiveFailures != null && metadata.consecutiveFailures > 0)
    return `${metadata.consecutiveFailures} consecutive failures`;
  if (metadata.healthy) return "Healthy";
  return "Unhealthy";
}

function UpdatePermissionsButton(props: {
  integration: components["schemas"]["Integration"];
}) {
  let component: React.ReactNode;
  switch (props.integration.platform) {
    case IntegrationPlatform.MICROSOFT:
      component = (
        <UpdateMicrosoftPermissions integration={props.integration} />
      );
      break;
    case IntegrationPlatform.GOOGLE:
      component = <UpdateGooglePermissions integration={props.integration} />;
      break;
    default:
      component = null;
  }
  return component;
}
