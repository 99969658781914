import { AppLayout } from "@/components/app-layout";
import ManageExclusion from "@/components/manage-exclusion";
import { TableCard } from "@/components/table-card";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { dateTime } from "@/lib/time";
import { getDetailsFromRaw } from "@/lib/utils";
import {
  keepPreviousData,
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import {
  createDetectionQuery,
  DETECTION_QUERY_FIELDS,
  getStatusConfigByStatus,
  QUERY_OPERATOR,
  ROLE,
} from "@wire/shared";
import { useMemo, useState } from "react";

export const Route = createFileRoute("/_application/assets/ips/$ipId")({
  component: IP,
  loader: async ({ params, context }) => {
    const response = await context.queryClient.ensureQueryData(
      getOptions(params.ipId)
    );
    context.title = response?.displayName;
  },
});

async function getData(ipId: string) {
  const ip = await apiClient.GET("/ip/{id}", {
    params: { path: { id: ipId } },
  });
  if (ip.error != null) {
    throw new Error("Error getting IP information");
  }
  return ip.data;
}

export const IP_QUERY_KEY = "ip";
const getOptions = (ipId: string) =>
  queryOptions({
    queryKey: [IP_QUERY_KEY, ipId],
    queryFn: () => getData(ipId),
  });

async function searchUsers(
  searchSettings: components["schemas"]["DirectoryUserSearchDto"]
) {
  const users = await apiClient.POST("/directory", {
    body: searchSettings,
  });
  if (users.error != null) {
    throw new Error("Error getting user information");
  }
  return users.data;
}
export const USER_QUERY_KEY = "ip-user";
const getSearchUserOptions = (
  searchSettings: components["schemas"]["DirectoryUserSearchDto"]
) =>
  queryOptions({
    queryKey: [USER_QUERY_KEY, searchSettings],
    queryFn: () => searchUsers(searchSettings),
    placeholderData: keepPreviousData,
  });

async function searchEndpoints(
  searchSettings: components["schemas"]["EndpointSearchDto"]
) {
  const users = await apiClient.POST("/endpoint", {
    body: searchSettings,
  });
  if (users.error != null) {
    throw new Error("Error getting endpoint information");
  }
  return users.data;
}
export const ENDPOINT_QUERY_KEY = "ip-endpoint";
const getSearchEndpointOptions = (
  searchSettings: components["schemas"]["EndpointSearchDto"]
) =>
  queryOptions({
    queryKey: [ENDPOINT_QUERY_KEY, searchSettings],
    queryFn: () => searchEndpoints(searchSettings),
    placeholderData: keepPreviousData,
  });

async function searchCases(
  searchSettings: components["schemas"]["SearchCasesDto"],
  ipId: string
) {
  const users = await apiClient.POST("/cases", {
    body: { ...searchSettings, assetId: ipId, assetType: "IP" },
  });
  if (users.error != null) {
    throw new Error("Error getting case information");
  }
  return users.data;
}
export const CASE_QUERY_KEY = "case-endpoint";
const getSearchCasesOptions = (
  searchSettings: components["schemas"]["SearchCasesDto"],
  ipId: string
) =>
  queryOptions({
    queryKey: [CASE_QUERY_KEY, searchSettings, ipId],
    queryFn: () => searchCases(searchSettings, ipId),
    placeholderData: keepPreviousData,
  });

function IP() {
  const { ipId } = Route.useParams();
  const [createExclusionDialogOpen, setCreateExclusionDialogOpen] =
    useState(false);
  const [userSearchSettings, setUserSearchSettings] = useState<
    components["schemas"]["DirectoryUserSearchDto"]
  >({ ipId });
  const [endpointSearchSettings, setEndpointSearchSettings] = useState<
    components["schemas"]["EndpointSearchDto"]
  >({ ipId });
  const [caseSearchSettings, setCaseSearchSettings] = useState<
    components["schemas"]["SearchCasesDto"]
  >({});
  const usersQuery = useQuery(getSearchUserOptions(userSearchSettings));
  const casesQuery = useQuery(getSearchCasesOptions(caseSearchSettings, ipId));
  const endpointsQuery = useQuery(
    getSearchEndpointOptions(endpointSearchSettings)
  );
  const { data: ip } = useSuspenseQuery(getOptions(ipId));
  const navigate = useNavigate();

  const defaultExclusionQuery = useMemo(() => {
    let values: [string, string] = ["", ""];
    if (ip.ipv4 != null) {
      values = [DETECTION_QUERY_FIELDS.IP_IPV4, ip.ipv4];
    } else if (ip.ipv6 != null) {
      values = [DETECTION_QUERY_FIELDS.IP_IPV6, ip.ipv6];
    } else {
      return "";
    }
    return createDetectionQuery(
      values[0],
      QUERY_OPERATOR.ALL_ARRAY_VALUES_EQUAL,
      values[1]
    );
  }, []);

  const rawDetails = useMemo(() => {
    if (ip.metadata == null) return "No metadata available";
    let details = getDetailsFromRaw(ip.metadata);
    return details.map((v) => (
      <div className="overflow-hidden" key={v.key}>
        <h2 className="font-semibold">{v.key}</h2>
        <span className="truncate whitespace-nowrap text-ellipsis">
          {v.value}
        </span>
      </div>
    ));
  }, [ip.metadata]);

  return (
    <AppLayout>
      <ManageExclusion
        title="Create Exclusion"
        query={defaultExclusionQuery}
        name={ip.displayName}
        providedDetectionSid
        detectionSid={casesQuery.data?.data[0]?.detectionSids[0]}
        description="Automatically close future detections that match this query"
        open={createExclusionDialogOpen}
        onClose={() => setCreateExclusionDialogOpen(false)}
      />
      <div className="flex flex-col gap-4">
        <Card>
          <CardHeader className="bg-muted/50 space-y-0 items-center mb-4 flex flex-col gap-4 lg:flex-row lg:justify-between">
            <div>
              <CardTitle>{ip.ipv4 ?? ip.ipv6}</CardTitle>
              <CardDescription>IP Address</CardDescription>
            </div>
            <DropdownMenu requiredRole={ROLE.ANALYST}>
              <DropdownMenuTrigger asChild>
                <Button>Actions</Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="max-w-64">
                <DropdownMenuItem
                  onClick={() => setCreateExclusionDialogOpen(true)}
                >
                  <div>
                    <h4 className="font-semibold">Create Exclusion</h4>
                    <p className="text-muted-foreground">
                      Automatically ignore detections that are associated with
                      this location
                    </p>
                  </div>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </CardHeader>
          <CardContent className="grid grid-cols-1 lg:grid-cols-2 overflow-auto gap-x-8 gap-y-2">
            <div>
              <h2 className="font-semibold">Created At</h2>
              <p>{dateTime(ip.createdAt)}</p>
            </div>
            {rawDetails}
          </CardContent>
        </Card>
        <TableCard
          onClick={(row) =>
            navigate({
              to: "/cases/$caseId",
              params: { caseId: row.id },
            })
          }
          query={casesQuery}
          onUpdate={(settings) =>
            setCaseSearchSettings({ ...caseSearchSettings, ...settings })
          }
          headers={[
            {
              display: "ID",
              key: "sid",
              sortable: true,
            },
            {
              display: "Name",
              key: "name",
              sortable: true,
              format(value, row) {
                return <div>{row.name}</div>;
              },
            },
            {
              display: "Status",
              key: "status",
              format: (value) => getStatusConfigByStatus(value)?.display,
            },
          ]}
        >
          <CardHeader className="bg-muted/50 mb-4">
            <CardTitle>Related Cases</CardTitle>
            <CardDescription>
              Cases that this IP has been associated with
            </CardDescription>
          </CardHeader>
        </TableCard>

        <TableCard
          onClick={(row) =>
            navigate({
              to: "/assets/users/$userId",
              params: { userId: row.id },
            })
          }
          query={usersQuery}
          onUpdate={(settings) =>
            setUserSearchSettings({ ...userSearchSettings, ...settings })
          }
          headers={[
            {
              display: "Name",
              key: "name",
              sortable: true,
            },
            {
              display: "Email",
              key: "email",
              sortable: true,
            },
          ]}
        >
          <CardHeader className="bg-muted/50 mb-4">
            <CardTitle>Related Users</CardTitle>
            <CardDescription>
              Users that this IP has been associated with
            </CardDescription>
          </CardHeader>
        </TableCard>
        <TableCard
          onClick={(row) =>
            navigate({
              to: "/assets/endpoints/$endpointId",
              params: { endpointId: row.id },
            })
          }
          query={endpointsQuery}
          onUpdate={(settings) =>
            setEndpointSearchSettings({
              ...endpointSearchSettings,
              ...settings,
            })
          }
          headers={[
            { display: "Name", key: "name", sortable: true },
            { display: "Live", key: "live", sortable: true },
            { display: "Private IP", key: "privateIpAddress", sortable: true },
            {
              display: "Public IP",
              key: "id",
              format(value, row) {
                return row.publicIPs?.map((v) => v.ipv4 ?? v.ipv6).join(", ");
              },
            },
            { display: "OS", key: "operatingSystem", sortable: true },
          ]}
        >
          <CardHeader className="bg-muted/50 mb-4">
            <CardTitle>Related Endpoints</CardTitle>
            <CardDescription>
              Endpoints that this IP has been associated with
            </CardDescription>
          </CardHeader>
        </TableCard>
      </div>
    </AppLayout>
  );
}
