import { HoverCardItem } from "@/components/hover-cards/util";
import { Button } from "@/components/ui/button";
import { apiClient } from "@/lib/api";
import { dateTime } from "@/lib/time";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { toast } from "sonner";

export default function UserHoverCard(props: { id: string }) {
  const { data } = useSuspenseQuery(
    queryOptions({
      queryKey: ["user", props.id],
      queryFn: async () => {
        const response = await apiClient.GET("/directory/{id}", {
          params: { path: { id: props.id } },
        });
        if (response.error != null) {
          throw new Error("Error getting user information");
        }
        return response.data;
      },
    })
  );

  async function contain() {
    let response = await apiClient.POST("/asset/directory/{id}/contain", {
      params: { path: { id: props.id } },
      body: {},
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    toast.success("User contained");
  }

  async function uncontain() {
    let response = await apiClient.POST("/asset/directory/{id}/uncontain", {
      params: { path: { id: props.id } },
      body: {},
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    toast.success("User uncontained");
  }

  return (
    <div className="flex flex-col gap-2">
      <HoverCardItem title="Name">{data.name ?? "-"}</HoverCardItem>
      <HoverCardItem title="Email">{data.email ?? "-"}</HoverCardItem>
      <HoverCardItem title="Last Sign In">
        {data.lastSignInAt != null ? dateTime(data.lastSignInAt) : "-"}
      </HoverCardItem>
      <HoverCardItem date title="Password Last Changed">
        {data.passwordLastChangedAt}
      </HoverCardItem>
      <HoverCardItem boolean title="Contained">
        {data.contained}
      </HoverCardItem>
      {data.containable && (
        <div className="flex gap-2">
          <Button size="sm" variant="outlineDestructive" onClick={contain}>
            Contain
          </Button>
          <Button size="sm" variant="outline" onClick={uncontain}>
            Uncontain
          </Button>
        </div>
      )}
    </div>
  );
}
