"use client";

import { ChevronsUpDown, ExternalLinkIcon } from "lucide-react";

import { useTheme } from "@/components/theme-provider";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "@/components/ui/sidebar";
import {
  apiClient,
  isImpersonating,
  logout,
  setImpersonatedAPIToken,
} from "@/lib/api";
import { cn } from "@/lib/utils";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Link, useRouteContext } from "@tanstack/react-router";
import { useEffect, useMemo, useState } from "react";

async function getAPIVersion() {
  try {
    const response = await apiClient.GET("/version");
    return response.data?.version ?? "";
  } catch (err) {
    return;
  }
}

async function getVersionsFromUI() {
  try {
    const response = await fetch(`/versions.json?${Math.random()}`);
    const data: { api: string; ui: string } = await response.json();
    return data;
  } catch (err) {
    return;
  }
}

function semanticVersionGreater(old?: string, current?: string) {
  const oldParts = old?.split(".").map(Number);
  const currentParts = current?.split(".").map(Number);
  if (
    oldParts == null ||
    currentParts == null ||
    oldParts.length != currentParts.length
  ) {
    return;
  }
  for (let i = 0; i < oldParts.length; i++) {
    if (currentParts[i] > oldParts[i]) {
      return true;
    }
  }
  return false;
}

interface Versions {
  api?: string;
  ui: string;
}

async function billing(e: React.MouseEvent<HTMLDivElement>) {
  e.preventDefault();
  const res = await apiClient.GET("/stripe/portal");
  if (res.error != null) {
    throw new Error("Error getting billing information");
  }
  const url = res.data.url;
  window.open(url, "_blank");
}

export function NavUser() {
  const { isMobile } = useSidebar();
  const { user, team } = useRouteContext({ from: "/_application" });
  const { setTheme } = useTheme();
  const initials = useMemo(() => {
    return user.email
      .split("@")[0]
      .split(/[^a-zA-Z]|(?=[A-Z])/)
      .filter((s) => s.length > 0)
      .map((s) => s[0].toUpperCase())
      .join("");
  }, [user.email]);
  const [apiUpdating, setAPIUpdating] = useState(false);
  const [uiUpdated, setUIUpdated] = useState(false);
  const [promptRefresh, setPromptRefresh] = useState(false);
  const [initialVersions, setInitialVersions] = useState<Versions>();
  const [currentVersions, setCurrentVersions] = useState<Versions>();

  useEffect(
    function handleAPIVersions() {
      let timeout: Timer;
      // The API does slow rollouts, so the UI may have updated and be expecting the new version
      // but one or multiple API servers are still on the old version, consider it updating either way
      if (
        semanticVersionGreater(initialVersions?.api, currentVersions?.api) ||
        semanticVersionGreater(currentVersions?.api, initialVersions?.api)
      ) {
        setAPIUpdating(true);
        // Give the API 2 minutes to finish rolling out
        setTimeout(() => {
          setAPIUpdating(false);
        }, 120000);
      }

      return () => clearTimeout(timeout);
    },
    [currentVersions?.api, initialVersions?.api]
  );

  useEffect(
    function handleUIVersions() {
      let timeout: Timer;
      if (semanticVersionGreater(initialVersions?.ui, currentVersions?.ui)) {
        setUIUpdated(true);
        // Give the update time to roll out
        timeout = setTimeout(() => {
          setPromptRefresh(true);
        }, 120_000);
      }

      return () => clearTimeout(timeout);
    },
    [currentVersions?.ui, initialVersions?.ui]
  );

  useEffect(() => {
    async function checkVersions() {
      const [apiVersion, uiResponse] = await Promise.all([
        getAPIVersion(),
        getVersionsFromUI(),
      ]);
      if (apiVersion == null || uiResponse == null) return;

      setCurrentVersions({
        api: apiVersion ?? currentVersions?.api,
        ui: uiResponse?.ui,
      });
    }
    let interval = setInterval(async () => {
      await checkVersions();
    }, 30_000);

    //eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      if (initialVersions == null) {
        setInitialVersions(await getVersionsFromUI());
      }
      await checkVersions();
    })();

    return () => clearInterval(interval);
  }, []);

  function stopImpersonating() {
    setImpersonatedAPIToken();
    window.location.reload();
  }
  const impersonating = useMemo(isImpersonating, [user]);
  return (
    <SidebarMenu className="border-t p-2">
      <Dialog open={promptRefresh} onOpenChange={setPromptRefresh}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>New Version Available</DialogTitle>
            <DialogDescription>
              A new version of Wirespeed is available
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button onClick={() => window.location.reload()}>Refresh</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      {impersonating && (
        <SidebarMenuButton tooltip={`Impersonating ${user.email}`} asChild>
          <div
            onClick={stopImpersonating}
            className="flex text-xs cursor-pointer font-bold text-destructive items-center gap-2"
          >
            <ExclamationTriangleIcon className="size-4 stroke-destructive " />
            <span>Impersonating {user.email}</span>
          </div>
        </SidebarMenuButton>
      )}
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              size="lg"
              className="data-[state=open]:bg-sidebar-accent px-1  data-[state=open]:text-sidebar-accent-foreground"
            >
              <Avatar className="h-8 w-8 rounded-lg">
                <AvatarFallback className="rounded-lg">
                  {initials}
                </AvatarFallback>
              </Avatar>
              <div className="grid flex-1 text-left text-sm leading-tight">
                <span className="truncate text-xs">{user.fullName}</span>
                <span className="truncate text-xs">{user.email}</span>
              </div>
              <ChevronsUpDown className="ml-auto size-4" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
            side={isMobile ? "bottom" : "right"}
            align="end"
            sideOffset={4}
          >
            <DropdownMenuLabel className="p-0 font-normal">
              <div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                <Avatar className="h-8 w-8 rounded-lg">
                  <AvatarFallback className="rounded-lg">
                    {initials}
                  </AvatarFallback>
                </Avatar>
                <div className="grid flex-1 text-left text-sm leading-tight">
                  <span className="truncate text-xs">{user.email}</span>
                </div>
              </div>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
              <DropdownMenuItem className="cursor-pointer" asChild>
                <Link to="/settings/profile">Profile</Link>
              </DropdownMenuItem>
              {team.stripeCustomer && (
                <DropdownMenuItem
                  className="cursor-pointer"
                  onClick={billing}
                  asChild
                >
                  <div className="flex items-center gap-1">
                    Billing <ExternalLinkIcon className="size-4" />
                  </div>
                </DropdownMenuItem>
              )}
              <DropdownMenuItem className="cursor-pointer" asChild>
                <Link to="/settings/notifications">Notifications</Link>
              </DropdownMenuItem>
              <DropdownMenuItem className="cursor-pointer" asChild>
                <Link to="/settings/system-log">System Log</Link>
              </DropdownMenuItem>
            </DropdownMenuGroup>
            <DropdownMenuSeparator />
            <DropdownMenuSub>
              <DropdownMenuSubTrigger>Toggle Theme</DropdownMenuSubTrigger>
              <DropdownMenuSubContent>
                <DropdownMenuItem onClick={() => setTheme("light")}>
                  Light
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => setTheme("dark")}>
                  Dark
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => setTheme("system")}>
                  System
                </DropdownMenuItem>
              </DropdownMenuSubContent>
            </DropdownMenuSub>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              className="cursor-pointer"
              onClick={() => logout()}
            >
              Log out
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <div className="flex justify-center gap-2 text-xs text-muted-foreground">
              <HoverCard openDelay={50} closeDelay={50}>
                <HoverCardTrigger asChild>
                  <span
                    className={cn("cursor-pointer", {
                      "text-red-500": uiUpdated,
                    })}
                  >
                    UI: v{initialVersions?.ui}
                  </span>
                </HoverCardTrigger>
                <HoverCardContent className="w-auto">
                  {uiUpdated ? "Update available" : "Latest version"}
                </HoverCardContent>
              </HoverCard>
              {" | "}
              <HoverCard openDelay={50} closeDelay={50}>
                <HoverCardTrigger asChild>
                  <span
                    className={cn("cursor-pointer", {
                      "text-yellow-500": apiUpdating,
                    })}
                  >
                    API: v{initialVersions?.api}
                  </span>
                </HoverCardTrigger>
                <HoverCardContent className="w-auto">
                  {apiUpdating ? "Update in progress" : "Latest version"}
                </HoverCardContent>
              </HoverCard>
            </div>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
