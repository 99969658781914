import { TableCard } from "@/components/table-card";
import { CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { apiClient, formatSearchQuery } from "@/lib/api";
import { components } from "@/lib/api.types";
import { getTimezone } from "@/lib/time";
import {
  keepPreviousData,
  queryOptions,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { getIntegrationConfigByPlatform, ROLE } from "@wire/shared";
import { useState } from "react";
import { toast } from "sonner";
export const Route = createFileRoute("/_application/assets/users/")({
  component: Users,
  loader: ({ context, deps }) => {
    //eslint-disable-next-line @typescript-eslint/no-floating-promises
    context.queryClient.ensureQueryData(getVIPAutomationsOptions());
    //eslint-disable-next-line @typescript-eslint/no-floating-promises
    context.queryClient.ensureQueryData(getTechnicalAutomationsOptions());
    //eslint-disable-next-line @typescript-eslint/no-floating-promises
    context.queryClient.ensureQueryData(getAdministrativeAutomationsOptions());
    //eslint-disable-next-line @typescript-eslint/no-floating-promises
    context.queryClient.ensureQueryData(getDirectoryUsersOptions());
  },
});

function getDirectoryUsersOptions(
  settings: components["schemas"]["PaginationDto"] = {}
) {
  return queryOptions({
    queryKey: [DIRECTORY_USERS_QUERY_KEY, settings],
    queryFn: () => getDirectoryUsers(settings),
    placeholderData: keepPreviousData,
  });
}

function getTechnicalAutomationsOptions(
  settings: components["schemas"]["PaginationDto"] = {}
) {
  return queryOptions({
    queryKey: [TECHNICAL_AUTOMATIONS_KEY, settings],
    queryFn: () => getTechnicalAutomations(settings),
    placeholderData: keepPreviousData,
  });
}

const ADMINISTRATIVE_AUTOMATIONS_KEY = "settings-administrative-automations";
function getAdministrativeAutomationsOptions(
  settings: components["schemas"]["PaginationDto"] = {}
) {
  return queryOptions({
    queryKey: [ADMINISTRATIVE_AUTOMATIONS_KEY, settings],
    queryFn: () => getAdministrativeAutomations(settings),
    placeholderData: keepPreviousData,
  });
}

function getVIPAutomationsOptions(
  settings: components["schemas"]["PaginationDto"] = {}
) {
  return queryOptions({
    queryKey: [VIP_AUTOMATIONS_KEY, settings],
    queryFn: () => getVIPAutomations(settings),
    placeholderData: keepPreviousData,
  });
}

async function getVIPAutomations(
  searchSettings: components["schemas"]["PaginationDto"] = {}
) {
  const response = await apiClient.POST("/directory/vip/automation", {
    body: searchSettings,
  });

  if (response.error != null) {
    throw new Error("Error getting vip automations");
  }

  return response.data;
}

async function getAdministrativeAutomations(
  searchSettings: components["schemas"]["PaginationDto"] = {}
) {
  const response = await apiClient.POST("/directory/administrator/automation", {
    body: searchSettings,
  });

  if (response.error != null) {
    throw new Error("Error getting administrative automations");
  }

  return response.data;
}

async function getTechnicalAutomations(
  searchSettings: components["schemas"]["PaginationDto"] = {}
) {
  const response = await apiClient.POST("/directory/technical/automation", {
    body: searchSettings,
  });

  if (response.error != null) {
    throw new Error("Error getting technical automations");
  }

  return response.data;
}
async function getDirectoryUsers(
  searchSettings: components["schemas"]["DirectoryUserSearchDto"] = {
    field: "title",
  }
) {
  let search = formatSearchQuery(searchSettings.search);
  const response = await apiClient.POST("/directory", {
    body: { ...searchSettings, search },
  });

  if (response.error != null) {
    throw new Error("Error getting directory users");
  }

  return response.data;
}

const VIP_AUTOMATIONS_KEY = "settings-vip-automations";
const TECHNICAL_AUTOMATIONS_KEY = "technical-vip-automations";
const DIRECTORY_USERS_QUERY_KEY = "settings-directory-users";

export default function Users() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [directoryUserSearchSettings, setDirectoryUserSearchSettings] =
    useState<components["schemas"]["DirectoryUserSearchDto"]>();
  const [
    administrativeAutomationSearchSettings,
    setAdministrativeAutomationSearchSettings,
  ] = useState<components["schemas"]["PaginationDto"]>();
  const [vipAutomationSearchSettings, setVIPAutomationSearchSettings] =
    useState<components["schemas"]["PaginationDto"]>();
  const [
    technicalAutomationSearchSettings,
    setTechnicalAutomationSearchSettings,
  ] = useState<components["schemas"]["PaginationDto"]>();

  const directoryUsersQuery = useQuery(
    getDirectoryUsersOptions(directoryUserSearchSettings)
  );
  const vipAutomationsQuery = useQuery(
    getVIPAutomationsOptions(vipAutomationSearchSettings)
  );
  const technicalAutomationsQuery = useQuery(
    getTechnicalAutomationsOptions(technicalAutomationSearchSettings)
  );
  const administrativeAutomationsQuery = useQuery(
    getAdministrativeAutomationsOptions(administrativeAutomationSearchSettings)
  );

  async function deleteVIPAutomation(id: string) {
    const response = await apiClient.DELETE("/directory/vip/automation/{id}", {
      params: { path: { id } },
    });

    if (response.error != null) {
      toast.error("Error deleting automation");
      return;
    }

    await queryClient.invalidateQueries({ queryKey: [VIP_AUTOMATIONS_KEY] });
    toast.warning("Automation deleted");
  }

  async function deleteTechnicalAutomation(id: string) {
    const response = await apiClient.DELETE(
      "/directory/technical/automation/{id}",
      {
        params: { path: { id } },
      }
    );

    if (response.error != null) {
      toast.error("Error deleting automation");
      return;
    }

    await queryClient.invalidateQueries({
      queryKey: [TECHNICAL_AUTOMATIONS_KEY],
    });
    toast.warning("Automation deleted");
  }
  async function deleteAdministrativeAutomation(id: string) {
    const response = await apiClient.DELETE(
      "/directory/administrator/automation/{id}",
      {
        params: { path: { id } },
      }
    );

    if (response.error != null) {
      toast.error("Error deleting automation");
      return;
    }

    await queryClient.invalidateQueries({
      queryKey: [ADMINISTRATIVE_AUTOMATIONS_KEY],
    });
    toast.warning("Automation deleted");
  }

  function updateDirectoryUserSearchSettings(
    settings: components["schemas"]["PaginationDto"]
  ) {
    setDirectoryUserSearchSettings({
      ...directoryUserSearchSettings,
      ...settings,
      field: directoryUserSearchSettings?.field ?? "title",
    });
  }

  async function updateDirectoryUser(
    id: string,
    dto: components["schemas"]["UpdateDirectoryUserDto"]
  ) {
    const response = await apiClient.PATCH("/directory/{id}", {
      params: {
        path: { id },
      },
      body: dto,
    });

    if (response.error != null) {
      return toast.error("Error updating user");
    }

    await queryClient.invalidateQueries({
      queryKey: [DIRECTORY_USERS_QUERY_KEY],
    });
    toast.success("User updated");
  }

  async function addVIPAutomation() {
    if (
      directoryUserSearchSettings == null ||
      directoryUserSearchSettings.field == null ||
      directoryUserSearchSettings.search == null
    ) {
      toast.error("Please select search field and search");
      return;
    }
    let search = formatSearchQuery(directoryUserSearchSettings.search);

    const response = await apiClient.PUT("/directory/vip/automation", {
      body: {
        search: search,
        field: directoryUserSearchSettings.field,
      },
    });

    if (response.error != null) {
      if (response.error.statusCode == 409) {
        toast.error("VIP automation already exists");
      } else {
        toast.error("Error creating VIP automation");
      }
      return;
    }

    await queryClient.invalidateQueries({
      queryKey: [DIRECTORY_USERS_QUERY_KEY],
    });
    await queryClient.invalidateQueries({
      queryKey: [VIP_AUTOMATIONS_KEY],
    });
    toast.success("VIP Automation created");
  }

  async function addTechnicalAutomation() {
    if (
      directoryUserSearchSettings == null ||
      directoryUserSearchSettings.field == null ||
      directoryUserSearchSettings.search == null
    ) {
      toast.error("Please select search field and search");
      return;
    }
    let search = formatSearchQuery(directoryUserSearchSettings.search);

    const response = await apiClient.PUT("/directory/technical/automation", {
      body: {
        search: search,
        field: directoryUserSearchSettings.field,
      },
    });

    if (response.error != null) {
      if (response.error.statusCode == 409) {
        toast.error("Technical user automation already exists");
      } else {
        toast.error("Error creating technical user automation");
      }
      return;
    }

    await queryClient.invalidateQueries({
      queryKey: [DIRECTORY_USERS_QUERY_KEY],
    });
    await queryClient.invalidateQueries({
      queryKey: [TECHNICAL_AUTOMATIONS_KEY],
    });
    toast.success("Technical User Automation created");
  }

  async function addAdministratorAutomation() {
    if (
      directoryUserSearchSettings == null ||
      directoryUserSearchSettings.field == null ||
      directoryUserSearchSettings.search == null
    ) {
      toast.error("Please select search field and search");
      return;
    }
    let search = formatSearchQuery(directoryUserSearchSettings.search);

    const response = await apiClient.PUT(
      "/directory/administrator/automation",
      {
        body: {
          search: search,
          field: directoryUserSearchSettings.field,
        },
      }
    );

    if (response.error != null) {
      if (response.error.statusCode == 409) {
        toast.error("Administrator user automation already exists");
      } else {
        toast.error("Error creating administrator user automation");
      }
      return;
    }

    await queryClient.invalidateQueries({
      queryKey: [DIRECTORY_USERS_QUERY_KEY],
    });
    await queryClient.invalidateQueries({
      queryKey: [ADMINISTRATIVE_AUTOMATIONS_KEY],
    });
    toast.success("Administrator User Automation created");
  }

  return (
    <div>
      <TableCard
        embedded
        onUpdate={updateDirectoryUserSearchSettings}
        query={directoryUsersQuery}
        searchable
        tableActions={[
          {
            display: "Add New VIP Automation",
            onClick: addVIPAutomation,
            requiredRole: ROLE.ANALYST,
            showOnSearch: true,
          },
          {
            display: "Add New Technical User Automation",
            onClick: addTechnicalAutomation,
            requiredRole: ROLE.ANALYST,
            showOnSearch: true,
          },
          {
            display: "Add New Administrator User Automation",
            onClick: addAdministratorAutomation,
            requiredRole: ROLE.ANALYST,
            showOnSearch: true,
          },
        ]}
        compact
        onClickNavigate={(row) => ({
          to: "/assets/users/$userId",
          params: { userId: row.id },
        })}
        searchFilters={[
          {
            label: "Search Field",
            placeholder: "Select field",
            defaultValue: "title",
            values: [
              { key: "name", display: "Name" },
              { key: "email", display: "Email" },
              { key: "title", display: "Job Title" },
              { key: "department", display: "Department" },
              { key: "role", display: "Role" },
            ],
            onSelect: (v: any) =>
              setDirectoryUserSearchSettings({
                ...directoryUserSearchSettings,
                page: 1,
                search: directoryUserSearchSettings?.search,
                field: v,
              }),
          },
        ]}
        headers={[
          { display: "Name", key: "name", sortable: true },
          { display: "Username", key: "username", sortable: true },
          { display: "Email", key: "email", sortable: true },
          { display: "Title", key: "title", sortable: true },
          {
            display: "Roles",
            key: "roles",
            truncate: 60,
            hover: true,
          },
          { display: "Department", key: "department", sortable: true },
          { display: "VIP", key: "vip", sortable: true },
          { display: "Technical", key: "technical", sortable: true },
          { display: "Administrator", key: "administrator", sortable: true },
          {
            display: "Source",
            key: "integrationPlatform",
            format: (v) => getIntegrationConfigByPlatform(v).display,
          },
          {
            display: `First Seen (${getTimezone()})`,
            key: "createdAt",
            sortable: true,
            dateTime: true,
          },
          {
            display: `Updated At (${getTimezone()})`,
            key: "updatedAt",
            sortable: true,
            dateTime: true,
          },
          {
            display: "VIP Locked",
            key: "vipOverriddenByUser",
            info: ` A user has manually updated the VIP status of this row, therefore it is excluded from all automations. To remove this lock, select the 3-dot menu and click "Disable VIP Lock".`,
          },
          {
            display: "Technical Locked",
            key: "technicalOverriddenByUser",
            info: ` A user has manually updated the technical status of this row, therefore it is excluded from all automations. To remove this lock, select the 3-dot menu and click "Disable Technical Lock".`,
          },
          {
            display: "Administrator Locked",
            key: "administratorOverriddenByUser",
            info: ` A user has manually updated the administrator status of this row, therefore it is excluded from all automations. To remove this lock, select the 3-dot menu and click "Disable Administrator Lock".`,
          },
        ]}
        rowActions={[
          {
            name: (row) => (row.vip ? `Disable VIP` : "Enable VIP"),
            onClick: (row) =>
              updateDirectoryUser(row.id, {
                vip: !row.vip,
                vipOverriddenByUser: true,
              }),
          },
          {
            name: (row) =>
              row.vipOverriddenByUser ? `Disable VIP Lock` : "Enable VIP Lock",
            onClick: (row) =>
              updateDirectoryUser(row.id, {
                vipOverriddenByUser: !row.vipOverriddenByUser,
              }),
          },
          {
            name: (row) =>
              row.technical ? `Disable Technical` : "Enable Technical",
            onClick: (row) =>
              updateDirectoryUser(row.id, {
                technical: !row.technical,
                technicalOverriddenByUser: true,
              }),
          },
          {
            name: (row) =>
              row.technicalOverriddenByUser
                ? `Disable Technical Lock`
                : "Enable Technical Lock",
            onClick: (row) =>
              updateDirectoryUser(row.id, {
                technicalOverriddenByUser: !row.technicalOverriddenByUser,
              }),
          },
          {
            name: (row) =>
              row.administrator
                ? `Disable Administrator`
                : "Enable Administrator",
            onClick: (row) =>
              updateDirectoryUser(row.id, {
                administrator: !row.administrator,
                administratorOverriddenByUser: true,
              }),
          },
          {
            name: (row) =>
              row.administratorOverriddenByUser
                ? `Disable Administrator Lock`
                : "Enable Administrator Lock",
            onClick: (row) =>
              updateDirectoryUser(row.id, {
                administratorOverriddenByUser:
                  !row.administratorOverriddenByUser,
              }),
          },
        ]}
      >
        <CardHeader>
          <div className="flex  gap-4 items-start lg:items-center flex-col lg:flex-row justify-between">
            <div className="flex flex-col gap-2">
              <CardTitle>Directory Users</CardTitle>
              <CardDescription>
                All users ingested from your directory integrations. Use{" "}
                <kbd>*</kbd> for a wildcard, <kbd>"quotes"</kbd> for exact
                match, or <kbd>_</kbd> for single character wildcard.
              </CardDescription>
            </div>
          </div>
        </CardHeader>
      </TableCard>
      <div className="grid grid-cols-1 border-t items-start xl:grid-cols-3 gap-4 xl:gap-0">
        <TableCard
          embedded
          onUpdate={setAdministrativeAutomationSearchSettings}
          query={administrativeAutomationsQuery}
          searchable
          headers={[
            { display: "Search Field", key: "searchField" },
            { display: "Search", key: "search" },
          ]}
          rowActions={[
            {
              name: "Delete Automation",
              onClick: (row) => deleteAdministrativeAutomation(row.id),
              confirm: true,
              confirmMessage:
                "Are you sure you want to delete this Administrator User Automation? This will remove all users identified from this automation.",
            },
          ]}
        >
          <CardHeader>
            <div className="flex items-start space-y-4 lg:space-y-0 lg:items-center flex-col lg:flex-row justify-between">
              <div>
                <CardTitle>Administrator User Automations</CardTitle>
                <CardDescription>
                  Search queries to automatically identify administrator users
                  in your organization. To create a new automation, issue a
                  search on the Users table above and select Actions &gt; Add
                  New Administrator User Automation.
                </CardDescription>
              </div>
            </div>
          </CardHeader>
        </TableCard>
        <div className="xl:border-l xl:border-r h-full">
          <TableCard
            embedded
            onUpdate={setVIPAutomationSearchSettings}
            query={vipAutomationsQuery}
            searchable
            headers={[
              { display: "Search Field", key: "searchField" },
              { display: "Search", key: "search" },
            ]}
            rowActions={[
              {
                name: "Delete Automation",
                onClick: (row) => deleteVIPAutomation(row.id),
                confirm: true,
                confirmMessage:
                  "Are you sure you want to delete this VIP Automation? This will also remove any VIPs identified from this automation.",
              },
            ]}
          >
            <CardHeader>
              <div className="flex items-start space-y-4 lg:space-y-0 lg:items-center flex-col lg:flex-row justify-between">
                <div>
                  <CardTitle>VIP Automations</CardTitle>
                  <CardDescription>
                    Search queries to automatically identify VIPs in your
                    organization. To create a new automation, issue a search on
                    the Users table above and select Actions &gt; Add New VIP
                    Automation.
                  </CardDescription>
                </div>
              </div>
            </CardHeader>
          </TableCard>
        </div>

        <TableCard
          onUpdate={setTechnicalAutomationSearchSettings}
          query={technicalAutomationsQuery}
          searchable
          embedded
          headers={[
            { display: "Search Field", key: "searchField" },
            { display: "Search", key: "search" },
          ]}
          rowActions={[
            {
              name: "Delete Automation",
              onClick: (row) => deleteTechnicalAutomation(row.id),
              confirm: true,
              confirmMessage:
                "Are you sure you want to delete this Technical User Automation? This will remove all users identified from this automation.",
            },
          ]}
        >
          <CardHeader>
            <div className="flex items-start space-y-4 lg:space-y-0 lg:items-center flex-col lg:flex-row justify-between">
              <div>
                <CardTitle>Technical User Automations</CardTitle>
                <CardDescription>
                  Search queries to automatically identify technical users in
                  your organization. To create a new automation, issue a search
                  on the Users table above and select Actions &gt; Add New
                  Technical User Automation.
                </CardDescription>
              </div>
            </div>
          </CardHeader>
        </TableCard>
      </div>
    </div>
  );
}
