import { IntegrationPlatform, IntegrationType } from "./integration.types";

export enum LogCategory {
  LOGIN = "LOGIN",
  UNKNOWN = "UNKNOWN",
}

export interface ILogCategoryConfig {
  category: LogCategory;
  types: IntegrationType[];
  display: string;
}

export const LogCategoryConfig: {
  [key in LogCategory]: ILogCategoryConfig;
} = {
  [LogCategory.LOGIN]: {
    category: LogCategory.LOGIN,
    display: "Login",
    types: [IntegrationType.DETECTIONS_IDENTITY],
  },
  [LogCategory.UNKNOWN]: {
    category: LogCategory.UNKNOWN,
    display: "Unknown",
    types: Object.values(IntegrationType),
  },
};

export function getLogConfigByCategory(category: string) {
  return LogCategoryConfig[category as LogCategory];
}

export interface LogMetadata {
  sourceCreatedAt: string;
}

export interface LogTemplate {
  id: string;
  platform: "microsoft-entra";
  name: string;
  preference: number;
  type: IntegrationType;
  category: LogCategory;
  rules: LogRule[];
  metadata?: LogMetadata;
  extractors?: LogTemplateExtractor[];
}

export interface ExtractedLog {
  type: IntegrationType;
  platform: IntegrationPlatform;
  category: LogCategory;
  extractions?: LogExtraction[];
  ruleDescription?: string;
  metadata?: LogMetadata;
}
export interface LogRule {
  description: string;
  match: string;
}

export enum LogAssetType {
  USER = "USER",
  ENDPOINT = "ENDPOINT",
  LOCATION = "LOCATION",
}

export interface ILogExtractionTypeConfig {
  category: LogAssetType;
  display: string;
}

export const LogExtractionTypeConfig: {
  [key in LogAssetType]: ILogExtractionTypeConfig;
} = {
  [LogAssetType.ENDPOINT]: {
    category: LogAssetType.ENDPOINT,
    display: "Endpoint",
  },
  [LogAssetType.USER]: {
    category: LogAssetType.USER,
    display: "User",
  },
  [LogAssetType.LOCATION]: {
    category: LogAssetType.LOCATION,
    display: "Location",
  },
};

export function getLogExtractionTypeConfigByValue(category: string) {
  return LogAssetType[category as LogAssetType];
}

export type LogExtraction = { exclude?: boolean } & (
  | { type: LogAssetType.USER; data: LogUserExtractor }
  | { type: LogAssetType.ENDPOINT; data: LogEndpointExtractor }
);

interface BaseExtractor {
  exclude?: boolean;
}

export interface LogTemplateExtractor {
  type: LogAssetType;
  user?: LogUserExtractor;
  endpoint?: LogEndpointExtractor;
}

export interface LogUserExtractor extends BaseExtractor {
  id?: string;
  email?: string;
  username?: string;
  userAgent?: string;
  ipAddress?: string;
}

export interface LogEndpointExtractor extends BaseExtractor {
  privateIpAddress?: string;
  publicIpAddress?: string;
  hostname?: string;
  id?: string;
  versionIdentifier?: string; // Things like the user agent, machine version, etc...
}
