import { TableCard } from "@/components/table-card";
import { CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { apiClient, formatSearchQuery } from "@/lib/api";
import { components } from "@/lib/api.types";
import {
  keepPreviousData,
  queryOptions,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useState } from "react";

export const Route = createFileRoute("/_application/assets/ips/")({
  component: IPs,
  loader: ({ context, deps }) => {
    //eslint-disable-next-line @typescript-eslint/no-floating-promises
    context.queryClient.ensureQueryData(getOptions());
  },
});

function getOptions(
  settings: components["schemas"]["SearchIPDto"] = { field: "ipv4" }
) {
  return queryOptions({
    queryKey: [IPS_QUERY_KEY, settings],
    queryFn: () => search(settings),
    placeholderData: keepPreviousData,
  });
}

async function search(
  searchSettings: components["schemas"]["SearchIPDto"] = { field: "ipv4" }
) {
  let search = formatSearchQuery(searchSettings.search);
  const response = await apiClient.POST("/ip", {
    body: { ...searchSettings, search },
  });

  if (response.error != null) {
    throw new Error("Error getting IPs");
  }

  return response.data;
}

const IPS_QUERY_KEY = "assets-ips";

export default function IPs() {
  const queryClient = useQueryClient();
  const [searchSettings, setSearchSettings] = useState<
    components["schemas"]["SearchIPDto"]
  >({ field: "ipv4" });
  const [
    exclusionAutomationSearchSettings,
    setExclusionAutomationSearchSettings,
  ] = useState<components["schemas"]["PaginationDto"]>({});
  const navigate = useNavigate();
  function updateSearchSettings(
    settings: components["schemas"]["PaginationDto"]
  ) {
    setSearchSettings({
      ...searchSettings,
      ...settings,
    });
  }
  const query = useQuery(getOptions(searchSettings));

  return (
    <TableCard
      embedded
      onUpdate={updateSearchSettings}
      query={query}
      searchable
      onClickNavigate={(row) => ({
        to: "/assets/ips/$ipId",
        params: { ipId: row.id },
      })}
      searchFilters={[
        {
          label: "Search Field",
          placeholder: "Select field",
          defaultValue: "ipv4",
          values: [
            { key: "ipv4", display: "IPv4" },
            { key: "ipv6", display: "IPv6" },
            { key: "service", display: "Service Name" },
          ],
          onSelect: (v: any) =>
            setSearchSettings({
              ...searchSettings,
              page: 1,
              search: searchSettings?.search,
              field: v,
            }),
        },
      ]}
      headers={[
        {
          display: "IP",
          key: "ipv4",
          sortable: true,
          format: (_, row) => row.ipv4 ?? row.ipv6,
        },
        {
          display: "City",
          key: "id",
          format: (_, row) => row.metadata?.city ?? "-",
        },
        {
          display: "Region",
          key: "id",
          format: (_, row) => row.metadata?.region ?? "-",
        },
        {
          display: "Country",
          key: "id",
          format: (_, row) => row.metadata?.country ?? "-",
        },
        {
          display: "Service Types",
          key: "id",
          info: (
            <div>
              <ul className="list-disc list-inside">
                <li>hosting</li>
                <li>vpn</li>
                <li>proxy</li>
                <li>tor</li>
                <li>relay</li>
              </ul>
            </div>
          ),
          format: (_, row) => {
            let values = Object.entries(row.metadata?.privacy ?? {})
              .filter(([_, v]) => v == true && _ != "service")
              .map(([k]) => k)
              .join(", ");
            if (values == null || values.length == 0) {
              return "-";
            }
            return values;
          },
        },
        {
          display: "Service Name",
          key: "id",
          format: (_, row) => {
            let service = row.metadata?.privacy?.service;
            if (service == null || service.length == 0) {
              return "-";
            }
            return service;
          },
        },
      ]}
    >
      <CardHeader>
        <div className="flex  gap-4 items-start lg:items-center flex-col lg:flex-row justify-between">
          <div className="flex flex-col gap-2">
            <CardTitle>IP Addresses</CardTitle>
            <CardDescription>
              IP addresses pulled from your environment
            </CardDescription>
          </div>
        </div>
      </CardHeader>
    </TableCard>
  );
}
