import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { dateTime } from "@/lib/time";
import {
  CheckIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { DialogTrigger } from "@radix-ui/react-dialog";
import { type ClassValue, clsx } from "clsx";
import { ReactNode, useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function oxfordComma(items: string[]) {
  if (items.length == 1) {
    return items[0];
  }
  if (items.length == 2) {
    return `${items[0]} and ${items[1]}`;
  }
  let last = items.pop();
  return `${items.join(", ")}, and ${last}`;
}

export function pluralize(
  count: number,
  singular: string,
  plural: string = `${singular}s`
) {
  return count == 1 ? singular : plural;
}

// Typically used to detect the first render of a component so we can skip it in useEffect
export const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

export function useDebounce<T>(delay: number, value?: T) {
  const [debouncedValue, setDebouncedValue] = useState<T | undefined>(value);
  const [debouncing, setDebouncing] = useState(false);

  useEffect(() => {
    if (value == debouncedValue) return;
    setDebouncing(true);
    const handler = setTimeout(() => {
      if (value != debouncedValue) {
        setDebouncedValue(value ?? undefined);
      }
      setDebouncing(false);
    }, delay);

    return () => {
      setDebouncing(false);
      clearTimeout(handler);
    };
  }, [value, delay]);

  function override(val?: T) {
    setDebouncedValue(val);
  }

  return { debounced: debouncedValue, debouncing, override };
}

export function dashCaseToTitleCase(s: string) {
  return s
    .replace(/[-_]/g, " ")
    .replace(
      /\w\S*/g,
      (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    );
}

export function capitalizeFirst(str: string) {
  return str[0].toUpperCase() + str.slice(1).toLowerCase();
}

export function ellipsisTruncate(str: string, length: number) {
  if (str.length > length) {
    return str.slice(0, length) + "...";
  }
  return str;
}

export function getDetailsFromRaw(raw: object) {
  let out: { key: string; value: ReactNode }[] = [];
  for (const [key, value] of Object.entries(raw)) {
    if (value == null) continue;
    if (Array.isArray(value) && value.length == 0) continue;
    let parsedValue = value;
    if (
      typeof value == "string" &&
      (value.startsWith("{") || value.startsWith("["))
    ) {
      parsedValue = safeJSONParse(value);
    }
    if (typeof parsedValue == "object") {
      out.push({
        key: camelCaseToTitleCase(key),
        value: (
          <Dialog>
            <DialogTrigger asChild>
              <Button size="icon" variant="outline">
                <MagnifyingGlassIcon className="h-4 w-4" />
              </Button>
            </DialogTrigger>
            <DialogContent className="max-h-[80vh] w-auto min-w-[256px] max-w-[80vw] flex flex-col overflow-hidden">
              <DialogHeader>
                <DialogTitle>{camelCaseToTitleCase(key)}</DialogTitle>
              </DialogHeader>
              <div className="overflow-auto">
                <UnicodeSafeJSONPre json={parsedValue} />
              </div>
            </DialogContent>
          </Dialog>
        ),
      });
    } else {
      out.push(getCaseDetailValue(key, value));
    }
  }
  return out;
}

// Highlights any malicious characters so the user isn't tricked by them
const UnicodeSafeJSONPre = (props: { json: object }) => {
  const isNonStandardChar = (char: string) => {
    const code = char.charCodeAt(0);
    return code > 127 || (code < 32 && code !== 10 && code !== 13);
  };

  const renderCharacters = () => {
    return JSON.stringify(props.json, null, 2)
      .split("")
      .map((char, index) => {
        if (isNonStandardChar(char)) {
          return (
            <span key={index} className="bg-red-500">
              [U+{char.charCodeAt(0).toString(16).toUpperCase()}]
            </span>
          );
        }
        return <span key={index}>{char}</span>;
      });
  };
  return (
    <pre className="whitespace-pre-nowrap text-left">{renderCharacters()}</pre>
  );
};

const acronyms = ["API", "IP", "ID", "URL", "HTML", "JSON", "XML"];

export function camelCaseToTitleCase(s: string) {
  if (s.includes(" ")) return s;
  return s
    .replace(/([A-Z]+)([A-Z][a-z])/g, "$1 $2") // handle consecutive caps followed by lowercase
    .replace(/([a-z])([A-Z])/g, "$1 $2") // handle camelCase
    .replace(/([A-Z]+)(?=[A-Z][a-z]|\d|\W|$)/g, "$1 ")
    .trim()
    .split(" ")
    .map((word) => {
      if (acronyms.includes(word)) {
        return word.toUpperCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
}

function getCaseDetailValue(key: string, value: any) {
  key = camelCaseToTitleCase(key);

  // Handle boolean values
  if (typeof value === "boolean") {
    return {
      key,
      value: value ? (
        <CheckIcon className="text-green-500 h-6 w-6" />
      ) : (
        <XMarkIcon className="text-red-500 h-6 w-6" />
      ),
    };
  }

  // Handle date/time values
  const isTimeField =
    key.toLowerCase().includes("time") && key.toLowerCase() !== "timezone";
  const isDateField = key.toLowerCase().includes("date");

  if (isTimeField || isDateField) {
    const formattedDate = dateTime(value);
    if (formattedDate != null) {
      value = formattedDate;
    }
  }

  // Add tooltip for long values or transformed values
  const needsTooltip = typeof value === "string" && value.length > 256;

  return {
    key,
    value: needsTooltip ? (
      <TooltipProvider>
        <Tooltip delayDuration={100}>
          <TooltipTrigger className="lg:text-right max-w-[512px] text-left break-all">
            {value}
          </TooltipTrigger>
          <TooltipContent className="max-w-[400px] text-md w-auto whitespace-normal">
            {value}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    ) : (
      value
    ),
  };
}

export function isUUID(val: string) {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
    val
  );
}

export function safeJSONParse(str?: string) {
  let parsed;
  if (str == null) return;

  try {
    parsed = JSON.parse(str);
  } catch (error) {
    console.error(error);
  }

  return parsed;
}
