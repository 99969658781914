import { AppLayout } from "@/components/app-layout";
import AddNewClient from "@/components/service-provider/add-new-client";
import { useEnforceServiceProvider } from "@/hooks/use-enforce-service-provider";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_application/settings/add-new-client")({
  component: AddNewClientRoute,
});

export default function AddNewClientRoute() {
  useEnforceServiceProvider();
  return (
    <AppLayout>
      <AddNewClient />
    </AppLayout>
  );
}
