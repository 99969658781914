import { HoverCardItem } from "@/components/hover-cards/util";
import { apiClient } from "@/lib/api";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";

export default function ExclusionHoverCard(props: { id: string }) {
  const { data } = useSuspenseQuery(
    queryOptions({
      queryKey: ["detection", props.id],
      queryFn: async () => {
        const response = await apiClient.GET("/exclusion/{idOrSid}", {
          params: { path: { idOrSid: props.id } },
        });
        if (response.error != null) {
          throw new Error("Error getting exclusion information");
        }
        return response.data;
      },
    })
  );

  return (
    <div className="flex flex-col gap-2">
      <HoverCardItem title="ID">{data.sid}</HoverCardItem>
      <HoverCardItem title="Name">{data.name}</HoverCardItem>
      <HoverCardItem title="Enabled">{data.enabled}</HoverCardItem>
      <HoverCardItem title="Rule">
        <span className="font-mono">{data.queryString}</span>
      </HoverCardItem>
    </div>
  );
}
