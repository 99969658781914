import { useRouteContext, useRouter } from "@tanstack/react-router";
import * as React from "react";

export function useEnforceServiceProvider() {
  const { team } = useRouteContext({ from: "/_application" });
  const router = useRouter();
  React.useEffect(() => {
    if (!team.serviceProvider) {
      void router.navigate({ to: "/dashboard" });
    }
  }, [team]);
}
