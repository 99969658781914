import { apiClient, formatSearchQuery } from "@/lib/api";
import { components } from "@/lib/api.types";
import { getTimezone } from "@/lib/time";
import { keepPreviousData, queryOptions } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { getIntegrationConfigByPlatform } from "@wire/shared";

async function getIntegration(id: string) {
  const response = await apiClient.GET("/integration/{id}", {
    params: { path: { id } },
  });
  if (response.error != null) {
    throw new Error("Error getting register information");
  }
  let integration = response.data;

  return { integration };
}

// Add new query keys and functions from logs.tsx
export const INTEGRATION_LOG_QUERY_KEY = "INTEGRATION_LOG";
export const INTEGRATION_LOG_DAILY_QUERY_KEY = "INTEGRATION_LOG_DAILY";

export const getIntegrationLogOptions = (
  dto: components["schemas"]["IntegrationLogSearchDto"] = {}
) => {
  return queryOptions({
    queryKey: [INTEGRATION_LOG_QUERY_KEY, dto],
    queryFn: () => getIntegrationLog(dto),
    placeholderData: keepPreviousData,
  });
};

export const getIntegrationLogDailyOptions = (
  dto: components["schemas"]["IntegrationLogSearchDto"] = {}
) => {
  return queryOptions({
    queryKey: [INTEGRATION_LOG_DAILY_QUERY_KEY, dto],
    queryFn: () => getIntegrationLogDaily(dto),
    placeholderData: keepPreviousData,
  });
};

async function getIntegrationLog(
  dto: components["schemas"]["IntegrationLogSearchDto"]
) {
  const response = await apiClient.POST("/integration/logs", {
    body: {
      ...dto,
      search: formatSearchQuery(dto.search),
    },
  });
  if (response.error != null) {
    throw new Error("Error getting integration logs");
  }
  return response.data;
}

async function getIntegrationLogDaily(
  dto: components["schemas"]["IntegrationLogSearchDailyDto"]
) {
  const response = await apiClient.POST("/integration/logs/daily", {
    body: { ...dto, timezone: getTimezone() },
  });
  if (response.error != null) {
    throw new Error("Error getting daily integration logs");
  }
  return response.data;
}

export const INTEGRATION_QUERY_KEY = "INTEGRATION";
export const getIntegrationOptions = (id: string) =>
  queryOptions({
    queryKey: [INTEGRATION_QUERY_KEY, id],
    queryFn: () => getIntegration(id),
  });

export const Route = createFileRoute(
  "/_application/settings/integrations/$integrationId"
)({
  loader: async ({ params, context }) => {
    const [integration] = await Promise.all([
      context.queryClient.ensureQueryData(
        getIntegrationOptions(params.integrationId)
      ),
      context.queryClient.ensureQueryData(
        getIntegrationLogOptions({ integrationId: params.integrationId })
      ),
      context.queryClient.ensureQueryData(
        getIntegrationLogDailyOptions({ integrationId: params.integrationId })
      ),
    ]);
    context.title = getIntegrationConfigByPlatform(
      integration.integration.platform
    ).display;
  },
});
