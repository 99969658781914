import { HoverCardItem } from "@/components/hover-cards/util";
import { Logo } from "@/components/ui/logo";
import { apiClient } from "@/lib/api";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { FileRiskTypeConfig } from "@wire/shared";

export default function FileHoverCard(props: { id: string }) {
  const { data } = useSuspenseQuery(
    queryOptions({
      queryKey: ["file-hover", props.id],
      queryFn: async () => {
        const response = await apiClient.GET("/file/{id}", {
          params: { path: { id: props.id } },
        });
        if (response.error != null) {
          throw new Error("Error getting file information");
        }
        return response.data;
      },
    })
  );

  return (
    <div className="flex flex-col gap-2">
      <HoverCardItem title="File Name">{data.name}</HoverCardItem>
      <HoverCardItem
        title={
          <div className="flex items-center gap-1">
            <span>Wirespeed Verdict</span>
            <Logo className="h-4 w-4" />
          </div>
        }
      >
        {FileRiskTypeConfig[data.fileRisk]?.name}
      </HoverCardItem>
    </div>
  );
}
