export enum NotificationFrequency {
  REALTIME = "REALTIME",
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
}

export enum NotificationType {
  NEW_CASE_ESCALATION = "NEW_CASE_ESCALATION",
  NEW_SECURITY_EVENT = "NEW_SECURITY_EVENT",
  NEW_CRITICAL_DEFECT = "NEW_CRITICAL_DEFECT",
  ACCOUNT_HEALTH = "ACCOUNT_HEALTH",
  ACTIVITY_SUMMARY = "ACCOUNT_SUMMARY",
}

export const SECURITY_NOTIFICATION_TYPES = [
  NotificationType.ACCOUNT_HEALTH,
  NotificationType.NEW_CRITICAL_DEFECT,
  NotificationType.NEW_CASE_ESCALATION,
];

export type NotificationTypeConfig = {
  display: string;
  description: string;
  type: NotificationType;
  defaultFrequency: NotificationFrequency;
  allowedFrequencies?: NotificationFrequency[];
  disableFrequency?: boolean;
};

export const NotificationTypeConfig: Record<
  NotificationType,
  NotificationTypeConfig
> = {
  [NotificationType.NEW_CASE_ESCALATION]: {
    display: "New Case Escalation",
    type: NotificationType.NEW_CASE_ESCALATION,
    defaultFrequency: NotificationFrequency.REALTIME,
    description: "A new case has been escalated in response to a new detection",
  },
  [NotificationType.ACCOUNT_HEALTH]: {
    display: "Account Health",
    type: NotificationType.ACCOUNT_HEALTH,
    allowedFrequencies: [
      NotificationFrequency.DAILY,
      NotificationFrequency.WEEKLY,
    ],
    defaultFrequency: NotificationFrequency.DAILY,
    description:
      "Updates on platform issues affecting the health of your account",
  },
  [NotificationType.ACTIVITY_SUMMARY]: {
    display: "Activity Summary",
    type: NotificationType.ACTIVITY_SUMMARY,
    allowedFrequencies: [
      NotificationFrequency.DAILY,
      NotificationFrequency.WEEKLY,
    ],
    defaultFrequency: NotificationFrequency.DAILY,
    description:
      "Recurring summary of activity in your account - automatically closed cases, new VIPs, etc...",
  },
  [NotificationType.NEW_SECURITY_EVENT]: {
    display: "New System Security Event",
    description: "A new security event has taken place in your account",
    type: NotificationType.NEW_SECURITY_EVENT,
    defaultFrequency: NotificationFrequency.REALTIME,
  },
  [NotificationType.NEW_CRITICAL_DEFECT]: {
    display: "New Critical Defect",
    description: "A new critical defect has been identified for your cases",
    type: NotificationType.NEW_CRITICAL_DEFECT,
    disableFrequency: true,
    defaultFrequency: NotificationFrequency.REALTIME,
  },
};

export const NOTIFICATION_TYPE_CONFIG_LIST = Object.values(
  NotificationTypeConfig
);

export const NOTIFICATION_TYPE_LIST = Object.values(NotificationType);

export function getNotificationTypeConfig(type: string) {
  return NotificationTypeConfig[type as NotificationType];
}

export const NotificationFrequencyConfig = {
  [NotificationFrequency.REALTIME.toString()]: {
    display: "Real Time",
    type: NotificationFrequency.REALTIME,
  },
  [NotificationFrequency.DAILY.toString()]: {
    display: "Daily",
    type: NotificationFrequency.DAILY,
  },
  [NotificationFrequency.WEEKLY.toString()]: {
    display: "Weekly",
    type: NotificationFrequency.WEEKLY,
  },
};

export const NOTIFICATION_FREQUENCY_CONFIG_LIST = Object.values(
  NotificationFrequencyConfig
);

export function getNotificationFrequencyConfig(type: string) {
  return NotificationFrequencyConfig[type as NotificationFrequency];
}
