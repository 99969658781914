import { TableCard } from "@/components/table-card";
import { CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { apiClient, formatSearchQuery } from "@/lib/api";
import { components } from "@/lib/api.types";
import {
  keepPreviousData,
  queryOptions,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useState } from "react";

async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
export const Route = createFileRoute("/_application/assets/processes/")({
  component: Processes,
  loader: ({ context, deps }) => {
    //eslint-disable-next-line @typescript-eslint/no-floating-promises
    context.queryClient.ensureQueryData(getOptions());
  },
});

function getOptions(settings: components["schemas"]["PaginationDto"] = {}) {
  return queryOptions({
    queryKey: [PROCESSES_QUERY_KEY, settings],
    queryFn: () => getProcesses(settings),
    placeholderData: keepPreviousData,
  });
}

async function getProcesses(
  searchSettings: components["schemas"]["PaginationDto"] = {}
) {
  let search = formatSearchQuery(searchSettings.search);
  const response = await apiClient.POST("/process", {
    body: { ...searchSettings, search },
  });

  if (response.error != null) {
    throw new Error("Error getting processes");
  }

  return response.data;
}

const PROCESSES_QUERY_KEY = "settings-processes";

export default function Processes() {
  const queryClient = useQueryClient();
  const [searchSettings, setSearchSettings] =
    useState<components["schemas"]["PaginationDto"]>();
  const navigate = useNavigate();
  function updateSearchSettings(
    settings: components["schemas"]["PaginationDto"]
  ) {
    setSearchSettings({
      ...searchSettings,
      ...settings,
    });
  }
  const query = useQuery(getOptions(searchSettings));

  return (
    <TableCard
      embedded
      onUpdate={updateSearchSettings}
      query={query}
      searchable
      onClickNavigate={(row) => ({
        to: "/assets/processes/$processId",
        params: { processId: row.id },
      })}
      compact
      headers={[
        { display: "Command", key: "command", sortable: true, truncate: 60 },
        { display: "SHA1", key: "sha1", sortable: true },
        { display: "SHA256", key: "sha256", sortable: true },
      ]}
    >
      <CardHeader>
        <div className="flex  gap-4 items-start lg:items-center flex-col lg:flex-row justify-between">
          <div className="flex flex-col gap-2">
            <CardTitle>Processes</CardTitle>
            <CardDescription>
              Process commands that have been extracted from detections in your
              environment.
            </CardDescription>
          </div>
        </div>
      </CardHeader>
    </TableCard>
  );
}
