import { HoverCardItem } from "@/components/hover-cards/util";
import { apiClient } from "@/lib/api";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";

export default function LocationHoverCard(props: { id: string }) {
  const { data } = useSuspenseQuery(
    queryOptions({
      queryKey: ["location", props.id],
      queryFn: async () => {
        const response = await apiClient.GET("/location/{id}", {
          params: { path: { id: props.id } },
        });
        if (response.error != null) {
          throw new Error("Error getting location information");
        }
        return response.data;
      },
    })
  );

  return (
    <div className="flex flex-col gap-2">
      <HoverCardItem title="City">{data.city ?? "-"}</HoverCardItem>
      <HoverCardItem title="Region">{data.state ?? "-"}</HoverCardItem>
      <HoverCardItem title="Latitude">{data.lat ?? "-"}</HoverCardItem>
      <HoverCardItem title="Longitude">{data.lon ?? "-"}</HoverCardItem>
    </div>
  );
}
