import { HoverCardItem } from "@/components/hover-cards/util";
import { apiClient } from "@/lib/api";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { getIntegrationConfigByPlatform } from "@wire/shared";

export default function IntegrationHoverCard(props: { id: string }) {
  const { data } = useSuspenseQuery(
    queryOptions({
      queryKey: ["integration", props.id],
      queryFn: async () => {
        const response = await apiClient.GET("/integration/{id}", {
          params: { path: { id: props.id } },
        });
        if (response.error != null) {
          throw new Error("Error getting integration information");
        }
        return response.data;
      },
    })
  );

  return (
    <div className="flex flex-col gap-2">
      <HoverCardItem title="Platform">
        {getIntegrationConfigByPlatform(data.platform)?.display}
      </HoverCardItem>
      <HoverCardItem title="Identifier">{data.identifier}</HoverCardItem>
      <HoverCardItem title="Healthy">{data.healthy}</HoverCardItem>
      <HoverCardItem date title="Last Used">
        {data.lastUsedAt}
      </HoverCardItem>
    </div>
  );
}
