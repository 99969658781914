import { ErrorPage } from "@/components/error-page";
import { SERVICE_PROVIDER_PATH } from "@/components/nav/nav-sidebar";
import { Toaster } from "@/components/ui/sonner";
import {
  getProperTeamForUUID,
  getUser,
  getUUIDFromPath,
  User,
} from "@/lib/api";
import { components } from "@/lib/api.types";
import { queryClient } from "@/lib/tanstack";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  createRootRouteWithContext,
  Outlet,
  useNavigate,
} from "@tanstack/react-router";
import { motion } from "framer-motion";
import { useEffect } from "react";

export const Route = createRootRouteWithContext<{
  queryClient: QueryClient;
  user: User;
  title?: string;
  path?: string;
  team: components["schemas"]["Team"];
  teams: components["schemas"]["Team"][];
}>()({
  component: RootComponent,
  notFoundComponent: NotFoundComponent,
  errorComponent: ErrorComponent,
  loader: ({ context }) => {
    if (context.team?.parentTeamId != null && context.user?.serviceProvider) {
      context.title = context.team.name;
    } else if (context.team?.serviceProvider) {
      context.title = "Service Provider";
    }
  },
});

function ErrorComponent(props: { error: Error }) {
  console.error(props.error);
  return <ErrorPage error={props.error.message} />;
}

function NotFoundComponent() {
  const user = getUser();
  const navigate = useNavigate();
  useEffect(() => {
    // Make sure if a SP is trying to view a non-sp page, we switch to the correct team
    //  so they don't get redirected back to '/sp' by the application
    // Otherwise always redirect to dashboard
    async function handleServiceProviderRedirect() {
      let uuid = getUUIDFromPath();
      if (location.pathname.startsWith(SERVICE_PROVIDER_PATH) && uuid != null) {
        const teamId = await getProperTeamForUUID(uuid);
        let newPath =
          location.pathname.replace(SERVICE_PROVIDER_PATH, "") +
          location.search;
        if (teamId != null && teamId != user.teamId) {
          await navigate({
            to: "/switch",
            search: { from: user.teamId, to: teamId, redirect: newPath },
          });
          return;
        } else if (teamId == user.teamId) {
          await navigate({ to: newPath });
          return;
        }
      }
      await navigate({ to: "/dashboard" });
    }
    void handleServiceProviderRedirect();
  }, [location.pathname, user.teamId]);

  return (
    <div className="h-screen w-screen flex flex-col space-y-8 items-center justify-center">
      <h1 className="text-2xl">Loading...</h1>
    </div>
  );
}

function RootComponent() {
  return (
    <QueryClientProvider client={queryClient}>
      <motion.div
        className="min-h-screen min-w-screen"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.25 }}
      >
        <Outlet />
      </motion.div>
      <Toaster richColors closeButton />
      {/* <ReactQueryDevtools initialIsOpen={true} /> */}
      {/* <TanStackRouterDevtools initialIsOpen={true} /> */}
    </QueryClientProvider>
  );
}
