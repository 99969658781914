import { HoverCardItem } from "@/components/hover-cards/util";
import { apiClient } from "@/lib/api";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";

export default function DomainHoverCard(props: { id: string }) {
  const { data } = useSuspenseQuery(
    queryOptions({
      queryKey: ["domain", props.id],
      queryFn: async () => {
        const response = await apiClient.GET("/domain/{id}", {
          params: { path: { id: props.id } },
        });
        if (response.error != null) {
          throw new Error("Error getting domain information");
        }
        return response.data;
      },
    })
  );

  return (
    <div className="flex flex-col gap-2">
      <HoverCardItem title="Domain Name">{data.name ?? "-"}</HoverCardItem>
    </div>
  );
}
