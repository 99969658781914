import AddAdminIntegration from "@/components/admin-settings/add-admin-integration";
import { AppLayout } from "@/components/app-layout";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { getIntegrationConfigByPlatform } from "@wire/shared";
import { useState } from "react";
import { toast } from "sonner";

export const Route = createFileRoute("/_application/admin/integrations")({
  loader: async ({ context }) => {
    await context.queryClient.ensureQueryData(getOptions());
  },
  component: AdminIntegrations,
});

async function getSettings() {
  const queues = await apiClient.GET("/admin/integration");
  if (queues.error != null) {
    throw new Error("Error getting settings information");
  }
  return queues.data;
}

const ADMIN_SETTINGS_QUERY_KEY = "admin-integration-settings";
const getOptions = () =>
  queryOptions({
    queryKey: [ADMIN_SETTINGS_QUERY_KEY],
    queryFn: getSettings,
  });

export default function AdminIntegrations() {
  const { data: integrations } = useSuspenseQuery(getOptions());
  return (
    <AppLayout>
      <Card>
        <CardHeader>
          <div className="flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:justify-between lg:items-center">
            <div>
              <CardTitle>Integration Settings</CardTitle>
            </div>
            <AddAdminIntegration />
          </div>
        </CardHeader>
        <CardContent>
          <ul className="grid grid-cols-1 gap-4 lg:grid-cols-3">
            {integrations.map((v) => (
              <Integration integration={v} />
            ))}
          </ul>
          {!integrations.length && (
            <p className="text-foreground-muted text-center">
              No integrations added yet
            </p>
          )}
        </CardContent>
      </Card>
    </AppLayout>
  );
}

function Integration({
  integration,
}: {
  integration: components["schemas"]["AdminIntegration"];
}) {
  const [clientKey, setClientKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [secondaryClientKey, setSecondaryClientKey] = useState("");
  const [secondarySecretKey, setSecondarySecretKey] = useState("");
  const [storeId, setStoreId] = useState("");
  const [stateSecret, setStateSecret] = useState("");
  const [signingSecret, setSigningSecret] = useState("");
  const [open, setOpen] = useState(false);

  async function update() {
    const response = await apiClient.PATCH("/admin/integration/{id}", {
      params: {
        path: { id: integration.id },
      },
      body: {
        clientKey,
        secretKey,
        signingSecret,
        stateSecret,
        storeId,
        secondaryClientKey,
        secondarySecretKey,
      },
    });
    if (response.error != null) {
      toast.error("Error updating integration");
      return;
    }
    toast.success("Integration updated");
    setClientKey("");
    setSecretKey("");
    setSigningSecret("");
    setStateSecret("");
    setSecondaryClientKey("");
    setSecondarySecretKey("");
    setStoreId("");
    setOpen(false);
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">
          {getIntegrationConfigByPlatform(integration.type)?.display}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-xl font-semibold">
            {getIntegrationConfigByPlatform(integration.type)?.display}
          </DialogTitle>
        </DialogHeader>
        <div className="flex flex-col space-y-4">
          <IntegrationInput
            label="Client Key"
            value={clientKey}
            hasValue={integration.clientKey}
            onChange={setClientKey}
          />
          <IntegrationInput
            label="Secret Key"
            value={secretKey}
            hasValue={integration.secretKey}
            onChange={setSecretKey}
          />
          <IntegrationInput
            label="Signing Secret"
            value={signingSecret}
            hasValue={integration.signingSecret}
            onChange={setSigningSecret}
          />
          <IntegrationInput
            label="State Secret"
            hasValue={integration.stateSecret}
            value={stateSecret}
            onChange={setStateSecret}
          />
          <IntegrationInput
            label="Store ID"
            hasValue={integration.storeId}
            value={storeId}
            onChange={setStoreId}
          />
          <IntegrationInput
            label="Secondary Client Key"
            hasValue={integration.secondaryClientKey}
            value={secondaryClientKey}
            onChange={setSecondaryClientKey}
          />
          <IntegrationInput
            label="Secondary Secret Key"
            hasValue={integration.secondarySecretKey}
            value={secondarySecretKey}
            onChange={setSecondarySecretKey}
          />
          <Button onClick={update}>Update</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

function IntegrationInput(props: {
  label: string;
  value: string;
  hasValue: boolean;
  onChange: React.Dispatch<React.SetStateAction<string>>;
}) {
  return (
    <div>
      <Label>{props.label}</Label>
      <Input
        value={props.value}
        type="password"
        autoComplete="off"
        onChange={(e) => props.onChange(e.target.value)}
      />
    </div>
  );
}
