"use client";
import ProfileSettings from "@/components/settings/profile";
import { apiClient } from "@/lib/api";
import { queryOptions } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";

export const SETTINGS_QUERY_KEY = "settings";
export const getProfileSettingsOptions = () =>
  queryOptions({
    queryKey: [SETTINGS_QUERY_KEY],
    queryFn: getSettings,
  });

async function getSettings() {
  const user = await apiClient.GET("/users");
  if (user.error != null) {
    throw new Error("Error getting settings information");
  }
  return user.data;
}

export const Route = createFileRoute("/_application/settings/profile")({
  loader: async ({ context }) => {
    await context.queryClient.ensureQueryData(getProfileSettingsOptions());
  },
  component: ProfileSettings,
});
