import { AppLayout } from "@/components/app-layout";
import {
  getNotificationQueryOptions,
  NOTIFICATION_SETTINGS_QUERY,
} from "@/components/settings/notifications.options";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  CustomSelectTrigger,
  Select,
  SelectContent,
  SelectItem,
  SelectValue,
} from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import { apiClient } from "@/lib/api";
import { CaretDownIcon } from "@radix-ui/react-icons";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useRouteContext } from "@tanstack/react-router";
import {
  getNotificationTypeConfig,
  NOTIFICATION_FREQUENCY_CONFIG_LIST,
  NOTIFICATION_TYPE_LIST,
  NotificationFrequency,
  NotificationType,
} from "@wire/shared";
import { FetchResponse } from "openapi-fetch";
import { useMemo } from "react";
import { toast } from "sonner";

export default function NotificationSettings() {
  const queryClient = useQueryClient();
  const notificationQuery = useQuery(getNotificationQueryOptions());
  const { team } = useRouteContext({ from: "/_application" });

  const notifications = useMemo(() => {
    if (notificationQuery.data == null) return;
    let out = [];
    const notificationTypes = NOTIFICATION_TYPE_LIST;
    for (const type of notificationTypes) {
      const config = getNotificationTypeConfig(type);
      const found = notificationQuery.data.find((v) => v.type == type);
      out.push({
        type: type,
        display: config.display,
        description: config.description,
        disableFrequency: config.disableFrequency,
        allowedFrequencies: config.allowedFrequencies,
        defaultFrequency: config.defaultFrequency,
        id: found?.id,
        frequency: found?.frequency,
      });
    }
    return out;
  }, [notificationQuery.data]);

  async function toggleNotificationSubscription(
    type: NotificationType,
    id?: string
  ) {
    let notification = notifications?.find((v) => v.type == type);
    if (notification == null) {
      toast.error("Error toggling notification subscription");
      return;
    }
    let response: FetchResponse<any, any, any>;
    if (id != null) {
      response = await apiClient.DELETE("/notification/subscription/{id}", {
        params: { path: { id: id } },
      });
    } else {
      response = await apiClient.PUT("/notification/subscription", {
        body: {
          type: type as any,
          frequency: notification.defaultFrequency,
        },
      });
    }
    if (response.error != null) {
      return toast.error("Error updating notification subscription");
    }
    if (id != null) {
      toast.warning("Unsubscribed from notification");
    } else {
      toast.success("Subscribed to notification");
    }
    await queryClient.invalidateQueries({
      queryKey: [NOTIFICATION_SETTINGS_QUERY],
    });
  }

  async function updateNotificationSubscriptionFrequency(
    id: string,
    frequency: NotificationFrequency
  ) {
    const response = await apiClient.PATCH("/notification/subscription/{id}", {
      params: { path: { id: id } },
      body: { frequency },
    });
    if (response.error != null) {
      toast.error("Error updating notification frequency");
    } else {
      toast.success("Notification subscription frequency updated");
    }
    await queryClient.invalidateQueries({
      queryKey: [NOTIFICATION_SETTINGS_QUERY],
    });
  }

  return (
    <AppLayout>
      <Card className="bg-transparent border-none shadow-none">
        <CardHeader>
          <CardTitle>Notification Settings</CardTitle>
          <CardDescription>
            Manage notification subscriptions and frequencies.
            {team.serviceProvider && (
              <>
                {" "}
                Subscription settings apply to all clients you are a provider
                for.
              </>
            )}
          </CardDescription>
        </CardHeader>
        <CardContent className="flex flex-col gap-4">
          {notifications?.map((notification) => (
            <div
              className="flex bg-background flex-row items-center justify-between rounded-lg border p-3 shadow-sm"
              key={notification.type}
            >
              <Switch
                className="mr-2"
                onCheckedChange={() =>
                  toggleNotificationSubscription(
                    notification.type,
                    notification.id
                  )
                }
                checked={!!notification.id}
              />
              <div className="flex-1">
                <div>
                  <div className="flex items-center gap-2">
                    <h2 className="font-bold">{notification.display}</h2>
                    {!notification.disableFrequency &&
                      notification.id != null && (
                        <Select
                          key={`${notification.type}-${notification.id}`}
                          disabled={!notification.id}
                          defaultValue={notification.frequency}
                          onValueChange={(e) =>
                            updateNotificationSubscriptionFrequency(
                              notification.id!,
                              e as NotificationFrequency
                            )
                          }
                        >
                          <CustomSelectTrigger className="text-xs flex items-center text-muted-foreground">
                            <SelectValue />
                            <CaretDownIcon className="h-4 w-4" />
                          </CustomSelectTrigger>
                          <SelectContent>
                            {getNotificationFrequencyConfigFromAllowedFrequencies(
                              notification.allowedFrequencies
                            ).map((v) => (
                              <SelectItem key={v.type} value={v.type}>
                                {v.display}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      )}
                  </div>
                  <p className="text-muted-foreground">
                    {notification.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </CardContent>
      </Card>
    </AppLayout>
  );
}

function getNotificationFrequencyConfigFromAllowedFrequencies(
  allowedFrequencies?: NotificationFrequency[]
) {
  if (allowedFrequencies == null || allowedFrequencies.length == 0) {
    return NOTIFICATION_FREQUENCY_CONFIG_LIST;
  }
  return NOTIFICATION_FREQUENCY_CONFIG_LIST.filter((v) =>
    allowedFrequencies.includes(v.type)
  );
}
