export default function Ellipsis(props: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="12"
      viewBox="0 0 24 12"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={props.className}
    >
      <circle cx="12" cy="6" r="1" />
      <circle cx="19" cy="6" r="1" />
      <circle cx="5" cy="6" r="1" />
    </svg>
  );
}
