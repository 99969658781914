import AWS from "@/components/settings/integrations/aws";
import HIBP from "@/components/settings/integrations/hibp";
import IPInfo from "@/components/settings/integrations/ipinfo";
import Okta from "@/components/settings/integrations/okta";
import ReversingLabs from "@/components/settings/integrations/reversing-labs";
import SentinelOne from "@/components/settings/integrations/sentinel-one";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { cn } from "@/lib/utils";
import { INTEGRATION_SETTINGS_QUERY } from "@/routes/_application/settings/integrations/";
import { update } from "@intercom/messenger-js-sdk";
import { PlusIcon } from "@radix-ui/react-icons";
import { useQueryClient } from "@tanstack/react-query";
import { useRouteContext } from "@tanstack/react-router";
import {
  IntegrationConfig,
  IntegrationPlatform,
  IntegrationType,
  ROLE,
  getIntegrationConfigByPlatform,
} from "@wire/shared";
import { useEffect, useMemo, useState } from "react";
import Email from "./integrations/email";
import Google from "./integrations/google";
import Microsoft from "./integrations/microsoft";
import Slack from "./integrations/slack";

export default function AddIntegration(props: {
  type?: IntegrationType;
  platform?: IntegrationPlatform;
  onboarding?: boolean;
  hideTrigger?: boolean;
  open?: boolean;
  onChange?: (open: boolean) => void;
}) {
  const queryClient = useQueryClient();
  const integrationConfig = useMemo(() => {
    if (props.platform) {
      return getIntegrationConfigByPlatform(props.platform);
    }
  }, [props.platform]);
  const { team, user } = useRouteContext({
    from: props.onboarding ? "/_onboarding" : "/_application",
  });
  const [name, setName] = useState(integrationConfig?.display);
  const [open, setOpen] = useState(props.open ?? false);
  const [integrationPlatform, setIntegrationPlatform] = useState<
    IntegrationPlatform | undefined
  >(props.platform);

  useEffect(() => {
    setIntegrationPlatform(props.platform);
    if (name == null) setName(integrationConfig?.display);
  }, [props.platform]);

  useEffect(() => {
    props.onChange?.(open);

    if (!open) {
      setName("");
      setIntegrationPlatform(undefined);
    }
  }, [open]);

  useEffect(() => {
    if (props.open != null) {
      setOpen(props.open);
    }
  }, [props.open]);

  async function complete() {
    setOpen(false);
    if (integrationPlatform != null) {
      getIntegrationConfigByPlatform(integrationPlatform).useCases.forEach(
        (useCase) => {
          update({
            id: user.id,
            company: {
              id: team.id,
              [`integration-added-${useCase.toLowerCase()}`]: true,
            },
          });
        }
      );
    }
    await queryClient.invalidateQueries({
      queryKey: [INTEGRATION_SETTINGS_QUERY],
    });
  }

  const integrations = useMemo(() => {
    let options = Object.values(IntegrationConfig);
    return options
      .sort((a, b) => a.display.localeCompare(b.display))
      .map((type) => (
        <SelectItem value={type.platform} key={type.platform}>
          <div className="flex gap-2 flex-row items-center">
            <IntegrationLogo platform={type.platform} />
            <span>{type.display}</span>
            {type.beta && <Badge className="text-xs">Beta</Badge>}
          </div>
        </SelectItem>
      ));
  }, [props.type]);

  return (
    <Dialog requiredRole={ROLE.ADMIN} open={open} onOpenChange={setOpen}>
      {!props.hideTrigger && (
        <DialogTrigger asChild>
          {team.serviceProvider ? (
            <HoverCard openDelay={0} closeDelay={50}>
              <HoverCardTrigger>
                <Button disabled>
                  <PlusIcon className="h-5 w-5 mr-2" />
                  Add new integration
                </Button>
              </HoverCardTrigger>
              <HoverCardContent>
                <p>
                  You can't add integrations to a service provider account,
                  please add them to the respective client accounts.
                </p>
              </HoverCardContent>
            </HoverCard>
          ) : (
            <Button>
              <PlusIcon className="h-5 w-5 mr-2" />
              Add new integration
            </Button>
          )}
        </DialogTrigger>
      )}
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add new integration</DialogTitle>
          <DialogDescription>
            Integrate with your security platforms to automate detection and
            response workflows.
          </DialogDescription>
        </DialogHeader>
        <div>
          <div className="space-y-4">
            <div>
              <Label>Type</Label>
              <Select
                defaultValue={integrationPlatform}
                onValueChange={(e) => {
                  setIntegrationPlatform(e as IntegrationPlatform);
                  if (name == null)
                    setName(
                      getIntegrationConfigByPlatform(e as IntegrationPlatform)
                        .display
                    );
                }}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select an integration" />
                </SelectTrigger>
                <SelectContent>{integrations}</SelectContent>
              </Select>
            </div>
            {integrationPlatform && (
              <IntegrationTypeComponent
                onboarding={props.onboarding}
                onComplete={complete}
                platform={integrationPlatform}
              />
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export function IntegrationTypeComponent(props: {
  platform: IntegrationPlatform;
  onComplete: () => void;
  onboarding?: boolean;
}) {
  switch (props.platform) {
    case IntegrationPlatform.SLACK:
      return <Slack onComplete={props.onComplete} />;
    case IntegrationPlatform.GOOGLE:
      return (
        <Google onComplete={props.onComplete} onboarding={props.onboarding} />
      );
    case IntegrationPlatform.OKTA:
      return <Okta onComplete={props.onComplete} />;
    case IntegrationPlatform.SENTINEL_ONE:
      return <SentinelOne onComplete={props.onComplete} />;
    case IntegrationPlatform.MICROSOFT:
    case IntegrationPlatform.MICROSOFT_TEAMS:
      return (
        <Microsoft platform={props.platform} onComplete={props.onComplete} />
      );
    case IntegrationPlatform.EMAIL:
      return (
        <Email onboarding={props.onboarding} onComplete={props.onComplete} />
      );
    case IntegrationPlatform.HAVE_I_BEEN_PWNED:
      return <HIBP onComplete={props.onComplete} />;
    case IntegrationPlatform.IP_INFO:
      return <IPInfo onComplete={props.onComplete} />;
    case IntegrationPlatform.REVERSING_LABS:
      return <ReversingLabs onComplete={props.onComplete} />;
    case IntegrationPlatform.AWS:
      return <AWS onComplete={props.onComplete} />;
  }
}

export function IntegrationLogo(props: {
  platform: IntegrationPlatform | string;
  className?: string;
}) {
  const type = IntegrationConfig[props.platform as IntegrationPlatform];
  return type.logo ? (
    <img src={type.logo} className={cn("h-4 w-4", props.className)} />
  ) : (
    <div>
      <img
        src={type.lightLogo}
        className={cn("dark:hidden h-4 w-4", props.className)}
      />
      <img
        src={type.darkLogo}
        className={cn("hidden dark:block h-4 w-4", props.className)}
      />
    </div>
  );
}
