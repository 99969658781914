import { HoverCardItem } from "@/components/hover-cards/util";
import { apiClient } from "@/lib/api";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import {
  getStatusConfigByStatus,
  getSubcategoryTitleWithVerdict,
  getVerdictConfigByVerdict,
  NewDetectionSubCategory,
  Verdict,
} from "@wire/shared";

export default function DetectionHoverCard(props: { id: string }) {
  const { data } = useSuspenseQuery(
    queryOptions({
      queryKey: ["detection", props.id],
      queryFn: async () => {
        const response = await apiClient.GET("/detection/{idOrSid}", {
          params: { path: { idOrSid: props.id } },
        });
        if (response.error != null) {
          throw new Error("Error getting detection information");
        }
        return response.data;
      },
    })
  );

  return (
    <div className="flex flex-col gap-2">
      <HoverCardItem title="ID">{data.sid}</HoverCardItem>
      <HoverCardItem title="Category">
        {getSubcategoryTitleWithVerdict(
          data.verdict as Verdict,
          data.subcategory as NewDetectionSubCategory
        ) ?? "-"}
      </HoverCardItem>
      <HoverCardItem title="Title">{data.sourceName}</HoverCardItem>
      <HoverCardItem title="Status">
        {getStatusConfigByStatus(data.status).display}
      </HoverCardItem>
      <HoverCardItem title="Verdict">
        {getVerdictConfigByVerdict(data.verdict).display}
      </HoverCardItem>
    </div>
  );
}
