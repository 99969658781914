import { AppLayout } from "@/components/app-layout";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Link, useRouteContext } from "@tanstack/react-router";
import moment from "moment";
import numeral from "numeral";

import { ExclamationTriangleIcon, WrenchIcon } from "@heroicons/react/16/solid";
import {
  ArrowsRightLeftIcon,
  CheckBadgeIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";
import { getIntegrationConfigByPlatform } from "@wire/shared";

import { IntegrationLogo } from "@/components/settings/add-integration";
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { HoverCard, HoverCardContent } from "@/components/ui/hover-card";
import { Logo } from "@/components/ui/logo";
import { dateTimeBuilder } from "@/lib/time";
import { cn } from "@/lib/utils";
import { DashboardData } from "@/routes/_application/dashboard";
import { HoverCardTrigger } from "@radix-ui/react-hover-card";
import { useMemo } from "react";
import { Line, LineChart, XAxis } from "recharts";

const getChartConfig = (teams: string[]): ChartConfig => ({
  ...teams.reduce(
    (acc, team, index) => {
      acc[team] = {
        label: team,
        color: `hsl(var(--chart-${(index % 4) + 1}))`,
      };
      return acc;
    },
    {} as Record<string, { label: string; color: string }>
  ),
});

export default function SPDashboard({
  caseCount,
  weeklyCases,
  closedCases,
  mttr,
  mttd,
  mttv,
  integrations,
}: DashboardData) {
  const { user, team } = useRouteContext({ from: "/_application" });

  const formattedWeeklyCases = useMemo(() => {
    // Group by week first
    const weekGroups: {
      [week: string]: {
        [team: string]: number;
      };
    } = weeklyCases.reduce((acc: any, v) => {
      const week = moment(v.week).format("MMM DD");
      if (acc[week] == null) {
        acc[week] = {};
      }
      if (v.teamName != null) {
        acc[week][v.teamName] = v.count;
      }
      acc[week].week = week;
      return acc;
    }, {});

    // Ensure every team has data for every week
    const allTeams = new Set(
      weeklyCases.filter((v) => v.teamName != null).map((v) => v.teamName!)
    );
    Object.values(weekGroups).forEach((week: any) => {
      allTeams.forEach((team) => {
        if (week[team] == null) {
          week[team] = 0;
        }
      });
    });

    return Object.values(weekGroups);
  }, [weeklyCases]);

  const emptyChart = useMemo(() => {
    return weeklyCases.every((v) => v.count == 0);
  }, [weeklyCases]);

  const uniqueTeams = useMemo(() => {
    return [
      ...new Set(
        weeklyCases.filter((v) => v.teamName != null).map((v) => v.teamName!)
      ),
    ];
  }, [weeklyCases]);

  const integrationHealth = useMemo(() => {
    if (integrations.totalCount == 0) return 100;
    if (integrations.data == null) return 100;
    return Math.round(
      integrations.data.reduce((acc, v) => acc + v.healthyPercentage, 0) /
        integrations.data.length
    );
  }, [integrations]);

  const chartConfig = useMemo(() => getChartConfig(uniqueTeams), [uniqueTeams]);

  return (
    <AppLayout>
      <main className="flex flex-1 flex-col gap-4 md:gap-8">
        <div className="grid gap-4 grid-cols-1 lg:grid-cols-2 xl:gap-8 xl:grid-cols-4">
          <Link to={"/cases"} search={{ filter: "ESCALATED" }}>
            <Card className="hover:shadow-md h-full">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  Escalated Cases
                </CardTitle>
                <HealthIcon
                  icon={ExclamationTriangleIcon}
                  health={((10 - caseCount.count) / 10) * 100}
                />
              </CardHeader>
              <CardContent>
                <div className={cn("text-2xl font-bold")}>
                  {caseCount.count}
                </div>
              </CardContent>
            </Card>
          </Link>
          <Link to={"/settings/integrations"}>
            <Card className="hover:shadow-md h-full">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  Integration Health
                </CardTitle>
                <HealthIcon
                  icon={ArrowsRightLeftIcon}
                  health={integrationHealth}
                />
              </CardHeader>
              <CardContent>
                <div className={cn("text-2xl font-bold")}>
                  {integrationHealth}%
                </div>
              </CardContent>
            </Card>
          </Link>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 col-span-1 lg:col-span-2">
            <HoverCard openDelay={50} closeDelay={50}>
              <HoverCardTrigger asChild>
                <Link to="/cases">
                  <Card className="hover:shadow-md h-full">
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                      <CardTitle className="text-sm font-medium">
                        Mean Time To Verdict
                      </CardTitle>

                      <HealthIcon
                        icon={Logo}
                        fill
                        health={
                          mttv.average < 2_000
                            ? 100
                            : mttv.average > 10_000
                              ? 0
                              : 75
                        }
                      />
                    </CardHeader>
                    <CardContent>
                      {mttv.average == null ? (
                        <div className="text-2xl font-bold">N/A</div>
                      ) : (
                        <>
                          <div className="text-2xl font-bold">
                            {mttv.average > 10_000
                              ? moment.duration(mttv.average).humanize()
                              : `${numeral(mttv.average).format("0,0")}ms`}
                          </div>
                          <p className="text-xs text-muted-foreground">
                            {mttv.average > 10_000
                              ? `${numeral(mttv.average).format()} ms`
                              : ""}
                            {mttv.average > 10_000 && mttv.change != null
                              ? " | "
                              : ""}
                            {mttv.change != null &&
                              `${numeral(mttv.change).format("+0[a]%")} from last month`}
                          </p>
                        </>
                      )}
                    </CardContent>
                  </Card>
                </Link>
              </HoverCardTrigger>
              <HoverCardContent>
                MTTV is calculated from the time Wirespeed ingests an alert to
                the time a verdict is made.
              </HoverCardContent>
            </HoverCard>
            <HoverCard openDelay={50} closeDelay={50}>
              <HoverCardTrigger asChild>
                <Link to="/cases">
                  <Card className="hover:shadow-md h-full">
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                      <CardTitle className="text-sm font-medium">
                        Mean Time To Detect
                      </CardTitle>

                      <HealthIcon
                        icon={ClockIcon}
                        health={
                          mttd.average < 120
                            ? 100
                            : mttd.average > 60 * 60
                              ? 0
                              : 75
                        }
                      />
                    </CardHeader>
                    <CardContent>
                      {mttd.average == null ? (
                        <div className="text-2xl font-bold">N/A</div>
                      ) : (
                        <>
                          <div className="text-2xl font-bold">
                            {moment.duration(mttd.average * 1000).humanize()}
                          </div>
                          <p className="text-xs text-muted-foreground">
                            {numeral(mttd.average).format()} seconds
                            {mttd.change != null &&
                              ` | ${numeral(mttd.change).format("+0[a]%")} from last month`}
                          </p>
                        </>
                      )}
                    </CardContent>
                  </Card>
                </Link>
              </HoverCardTrigger>
              <HoverCardContent>
                MTTD is calculated from the time an event happens to the time a
                verdict is made.
              </HoverCardContent>
            </HoverCard>
            <HoverCard openDelay={50} closeDelay={50}>
              <HoverCardTrigger asChild>
                <Link to="/cases">
                  <Card className="hover:shadow-md h-full">
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                      <CardTitle className="text-sm font-medium">
                        Mean Time To Respond
                      </CardTitle>

                      <HealthIcon
                        icon={WrenchIcon}
                        health={
                          mttr.average < 120
                            ? 100
                            : mttr.average > 60 * 60
                              ? 0
                              : 75
                        }
                      />
                    </CardHeader>
                    <CardContent>
                      {mttr.average == null ? (
                        <div className="text-2xl font-bold">N/A</div>
                      ) : (
                        <>
                          <div className="text-2xl font-bold">
                            {moment.duration(mttr.average * 1000).humanize()}
                          </div>
                          <p className="text-xs text-muted-foreground">
                            {numeral(mttr.average).format()} seconds
                            {mttr.change != null &&
                              ` | ${numeral(mttr.change).format("+0[a]%")} from last month`}
                          </p>
                        </>
                      )}
                    </CardContent>
                  </Card>
                </Link>
              </HoverCardTrigger>
              <HoverCardContent>
                MTTR is calculated from the time an event happens to the time
                the case is closed.
              </HoverCardContent>
            </HoverCard>
          </div>
        </div>
        <Card>
          <CardHeader>
            <CardTitle>Trends</CardTitle>
            <CardDescription>Cases over the last 3 months</CardDescription>
          </CardHeader>
          <CardContent className="flex items-center justify-center">
            {emptyChart || formattedWeeklyCases.length < 2 ? (
              <div className="italic text-muted-foreground">
                Not enough data yet. Check back soon!
              </div>
            ) : (
              <ChartContainer
                className="w-full h-24 min-h-24"
                config={chartConfig}
              >
                <LineChart
                  accessibilityLayer
                  data={formattedWeeklyCases}
                  margin={{
                    top: 6,
                    bottom: 2,
                  }}
                >
                  <XAxis
                    dataKey="week"
                    tickLine={false}
                    axisLine={false}
                    tickMargin={8}
                  />
                  <ChartTooltip
                    cursor={false}
                    labelFormatter={(label) => `Week of ${label}`}
                    content={<ChartTooltipContent indicator="line" />}
                  />
                  {uniqueTeams.map((team, index) => (
                    <Line
                      key={team}
                      type="monotone"
                      dataKey={team}
                      stroke={chartConfig[team].color}
                      strokeWidth={2}
                      dot={false}
                    />
                  ))}
                  <ChartLegend
                    content={<ChartLegendContent />}
                    wrapperStyle={{
                      overflow: "auto",
                    }}
                  />
                </LineChart>
              </ChartContainer>
            )}
          </CardContent>
        </Card>
        <div className="grid gap-4 md:gap-8 grid-cols-1 xl:grid-cols-2">
          <Card>
            <CardHeader>
              <CardTitle>Recently Closed Cases</CardTitle>
            </CardHeader>
            <CardContent className="grid gap-2 max-h-96 overflow-auto">
              {closedCases.data.length == 0 && (
                <div className="flex flex-col space-y-8 items-center">
                  <img
                    src="/illustrations/no-cases.svg"
                    className="w-auto h-[256px]"
                  />
                  <h3 className="text-xl">No closed cases yet</h3>
                </div>
              )}
              {closedCases.data.map((v) => (
                <Link
                  key={v.id}
                  to="/cases/$caseId"
                  params={{ caseId: v.id }}
                  search={{ switchTeamId: v.teamId }}
                  target="_blank"
                >
                  <div className="grid lg:grid-cols-4 grid-cols-1 hover:bg-muted p-2 rounded-md items-center gap-4">
                    <div className="col-span-4 flex space-x-2">
                      <CheckBadgeIcon className="h-9 w-9 flex-shrink-0 text-green-500" />
                      <div className="flex flex-col gap-1 justify-center">
                        <p className="text-sm font-semibold">{v.title}</p>
                        <p className="text-sm break-words hyphens-auto w-full text-muted-foreground">
                          {v.teamName} - {v.name}
                        </p>
                      </div>
                    </div>
                    {v.closedAt && (
                      <div className="flex-shrink-0 text-muted-foreground lg:text-right text-sm">
                        {dateTimeBuilder(v.closedAt).fromNow()}
                      </div>
                    )}
                  </div>
                </Link>
              ))}
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Integrations</CardTitle>
            </CardHeader>
            <CardContent className="grid gap-2 max-h-96 overflow-auto">
              {integrations.data.length == 0 && (
                <div className="flex flex-col space-y-8 items-center">
                  <img
                    src="/illustrations/lost.svg"
                    className="w-auto h-[256px]"
                  />
                  <h3 className="text-xl">No integrations yet</h3>
                </div>
              )}
              {integrations.data.map((v) => {
                return (
                  <Link
                    key={v.id}
                    to="/settings/integrations/$integrationId"
                    params={{ integrationId: v.id }}
                    search={{ switchTeamId: v.teamId }}
                    target="_blank"
                  >
                    <div className="grid lg:grid-cols-4 grid-cols-1 hover:bg-muted p-2 rounded-md items-center gap-4">
                      <div className="col-span-3 items-center flex space-x-2">
                        <IntegrationLogo
                          className="h-6 w-6"
                          platform={v.platform}
                        />
                        <div className="grid gap-1">
                          <p className="text-sm font-medium leading-none">
                            {getIntegrationConfigByPlatform(v.platform).display}
                          </p>
                          <p className="text-sm text-muted-foreground">
                            {v.teamName} -{" "}
                            {v.lastUsedAt != null
                              ? `Last used ${dateTimeBuilder(v.lastUsedAt).fromNow()}`
                              : "-"}
                          </p>
                        </div>
                      </div>
                      <div className="flex-shrink-0 text-muted-foreground lg:text-right text-sm"></div>
                    </div>
                  </Link>
                );
              })}
            </CardContent>
          </Card>
        </div>
      </main>
    </AppLayout>
  );
}

function HealthIcon(props: {
  health: number;
  fill?: boolean;
  icon: React.FC<{ className?: string }>;
}) {
  return (
    <props.icon
      className={cn("h-4 w-4 text-muted-foreground", {
        "text-green-500": props.health == 100,
        "text-yellow-500": props.health >= 75 && props.health < 100,
        "text-red-500": props.health < 75,
        "fill-green-500": props.fill && props.health == 100,
        "fill-yellow-500":
          props.fill && props.health >= 75 && props.health < 100,
        "fill-red-500": props.fill && props.health < 75,
      })}
    />
  );
}
